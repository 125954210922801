import API from "./Config";
import ENDPOINTS from "./Enpoints";

const BitacoraService = {
    getAll: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.BITACORA)
        .then(
            res => res.data
          )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.BITACORA, data)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
}

export default BitacoraService;