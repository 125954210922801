import API from "./Config";
import ENDPOINTS from "./Enpoints";

const VentasService = {
    getAll: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.QUOTES+"s/all")
        .then(
            res => res.data
          )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    changeStatus: (id) => new Promise((resolve, reject) => {
      API.get(ENDPOINTS.QUOTES+"q/change-status/"+id)
      .then(
          res => res.data
        )
        .then(
          data => resolve(data)
        )
        .catch(
          err => reject(err)
        )
  }),
}

export default VentasService;