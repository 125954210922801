import React ,{ useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { CRow } from '@coreui/react';
import { Form, Col, Input, Select } from 'antd';
import VehiculosService from 'src/services/VehiculosService';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import RetomasService from 'src/services/Retoma';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { InputNumber } from 'antd';
import retoma from '../../../src/assets/images/retoma.jpeg';
import ImageUploading from 'react-images-uploading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
const { TextArea } = Input;

const EditarRetoma = () => {
    const [vehiclesList, setVehiclesList] = useState([]);
    const [vehicleFoto, setVehicleFoto] = useState([]);
    const [linkTucarro, setLinkTucarro] = useState('#');
    const [aprobarButton, setAprobarButton] = useState('APROBAR')
    const [rechazarButton, setRechazarButton] = useState('RECHAZAR')
    const [estatus, setStatus] = useState(false);
    const [estado, setEstado] = useState('modificado');
    const [categoria, setCategoria] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [showLot, setShowLot] = useState(false);
    const [carSelected, setCarSelected] = useState();
    const [dataDef, setdataDef] = useState({
        estado: 'pro aprobar'
    });
    const [form] = Form.useForm();
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const { id } = useParams();
    const [index, setIndex] = useState(-1);
    var currentImage = vehicleFoto[index];
    const nextIndex = (index + 1) % vehicleFoto.length;
    const nextImage = vehicleFoto[nextIndex] || currentImage;
    const prevIndex = (index + vehicleFoto.length - 1) % vehicleFoto.length;
    const prevImage = vehicleFoto[prevIndex] || currentImage;

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getVehicles();
    }, [])

    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const getVehicles = () =>{
        VehiculosService.getActives().then(resp =>{
            let items = [];
            resp.data.forEach(element => {
                items.push({ value: element.id, label: element.title })
            });
            setVehiclesList(items);
            RetomasService.getOne(id).then(item =>{
                let _resp = item.data[0];
                let _images = [];
                item.data_fotos.forEach(element => {
                    _images.push({
                        src: element.foto,
                        original: element.foto,
                        width: 500,
                        height: 400,
                        caption: element.foto,
                        id: element.id
                    })
                });
                setVehicleFoto(_images)
                let vehicles = resp.data;
                console.log(_resp.plate)
                let car = vehicles.filter(x => x.plate === _resp.plate);
                car = car[0];
                setCarSelected(car)
                let _respData = {
                    vehiculo_venta: _resp.title+"  "+_resp.plate,
                    observaciones: _resp.observaciones,
                    vehiculo_retoma: _resp.vehiculo_retoma,
                    km_vehiculo_retoma: _resp.km_vehiculo_retoma,
                    promedio_tiempo_venta: _resp.promedio_tiempo_venta,
                    prec_ref_veh_retomar: _resp.precio_ref_veh_retoma,
                    prec_ref_veh_venta: _resp.precio_ref_veh_venta,
                    link_ref_tucarro: _resp.link_tucarro_referencia,
                    diferencia_neta: _resp.diferencia_neta,
                    estado: _resp.estado,
                    plate: _resp.plate,
                    title: _resp.title,
                    precio_estimado_venta: _resp.precio_estimado_venta, 
                    utilidad_esperada: _resp.utilidad_esperada, 
                    rentabilidad: _resp.rentabilidad
                }
                setCategoria(_resp.categoria)
                if(authUser().email.includes('roberth.ortiz') || authUser().email.includes('santiago.guerrero') || authUser().email.includes('carlos.duarte')){ setStatus(true) }
                setdataDef(_resp);
                setLinkTucarro(_resp.link_tucarro_referencia)
                form.setFieldsValue(_respData);             
            })
        })
    }

    const changeVehicle = () =>{
        let _diferencia_neta = parseInt(form.getFieldValue('prec_ref_veh_venta')) - parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        form.setFieldsValue({
            diferencia_neta: _diferencia_neta,
            prec_ref_veh_retomar: parseInt(form.getFieldValue('prec_ref_veh_retomar')),
        });
        changePEVenta()
    }

    const changeVehicleVenta = () =>{
        let _diferencia_neta = parseInt(form.getFieldValue('prec_ref_veh_venta')) - parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        form.setFieldsValue({
            diferencia_neta: _diferencia_neta,
            prec_ref_veh_venta: parseInt(form.getFieldValue('prec_ref_veh_venta')),
        });
        changePEVenta()
    }

    const buttonClick = (estado) =>{
        if(estado === 'modificar'){
            if(aprobarButton === 'MODIFICAR Y APROBAR'){
                form.submit();
            }else{
                setEstado('modificado');
                setStatus(false);
                setAprobarButton('MODIFICAR Y APROBAR')
                setRechazarButton('MODIFICAR Y RECHAZAR')
            }
        }
        if(estado === 'aprobar'){
            setEstado('aprobado')
            form.submit();
        }
        if(estado === 'rechazar'){
            setEstado('rechazado');
            form.submit();
        }
        if(estado === 'actualizar'){
            form.submit();
        }
    }

    const onFinish = (values) =>{
        let neto = parseInt(form.getFieldValue('prec_ref_veh_venta')) - parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        let data = {
            estado: estado,
            diferencia_neta: neto,
            vehiculo_venta: carSelected ? carSelected.id : 0,
            vehiculo_retoma: values.vehiculo_retoma, 
            km_vehiculo_retoma: values.km_vehiculo_retoma,
            promedio_tiempo_venta: values.promedio_tiempo_venta,
            precio_ref_veh_retomar: parseInt(values.prec_ref_veh_retomar),
            prec_ref_veh_venta: parseInt(values.prec_ref_veh_venta),
            link_tucarro_referencia: values.link_ref_tucarro,
            observaciones: values.observaciones ? values.observaciones : '',
            user_mod_id: authUser().id,
            title: dataDef.title,
            plate: dataDef.plate,
            precio_estimado_venta: values.precio_estimado_venta, 
            utilidad_esperada: values.utilidad_esperada, 
            rentabilidad: values.rentabilidad
        };
        console.log(data)
        RetomasService.update(data, id).then(resp=>{
            if(values.fotos && values.fotos.length > 0){
                let images = [];
                values.fotos.forEach( async (element, index) => {
                    const data = new FormData() 
                    data.append('file', element.file); 
                    await VehiculosService.uploadPhoto(data, "retoma_"+id).then(async response =>{
                        let _imagen = {
                            retoma_id: id, 
                            foto: response.uri
                        }
                        images.push(_imagen)                    
                    })
                    if((index + 1) == values.fotos.length){
                        images.forEach(element => {
                            RetomasService.saveImagens(element).then(response2 =>{ 
                                console.log("Imagen subida...")
                            })
                        });    
                        toast.success('Retoma editada con éxito', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });            
                        setShowLot(false)    
                        setTimeout(() => {                          
                        navigate('/retomas');
                        }, 2000);
                    }
                })
            }else{
                toast.success('Retoma editada con éxito', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });            
                setShowLot(false)    
                setTimeout(() => {                          
                navigate('/retomas');
                }, 2000);
            }
        })
    }
    const handleClick = (index) => {
        currentImage = vehicleFoto[index]
        setIndex(index);
    }

    const changePEVenta = () =>{
        let utilidad_esperada = parseInt(form.getFieldValue('precio_estimado_venta')) - parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        let rentabilidad = utilidad_esperada / parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        rentabilidad = (rentabilidad * 100).toFixed(2)
        console.log(rentabilidad)
        form.setFieldsValue({
            utilidad_esperada: utilidad_esperada,
            rentabilidad: rentabilidad
        });
    }

    const changeVenta = () =>{
        
    }

    const deleteImg = (currentImage) =>{
        RetomasService.delete(currentImage.id).then(resp =>{
            toast.success('Foto eliminada con éxito', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {                          
                window.location.reload();
            }, 1200);
        })
    }
    
    return(
        <>
            <ToastContainer />
            <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" />
            </div>
            <CRow>
                <Col md={12} xs={24} className='text-center mb-4 justify-content-center align-self-center'>
                    <div className='d-flex justify-content-center align-self-center'>
                        <h2>Editar retoma </h2>                        
                    </div>                
                </Col>
                {
                    vehicleFoto ? 
                    <Col md={12} xs={24} className='text-center mb-4'>
                        <Gallery
                            images={vehicleFoto}
                            onClick={handleClick}
                            enableImageSelection={false}
                            maxRows={1}
                        />
                        {!!currentImage && (
                            /* @ts-ignore */
                            <Lightbox
                                mainSrc={currentImage.original}
                                imageTitle={currentImage.caption}
                                mainSrcThumbnail={currentImage.src}
                                nextSrc={nextImage.original}
                                nextSrcThumbnail={nextImage.src}
                                prevSrc={prevImage.original}
                                prevSrcThumbnail={prevImage.src}
                                onCloseRequest={handleClose}
                                onMovePrevRequest={handleMovePrev}
                                onMoveNextRequest={handleMoveNext}
                                toolbarButtons={[
                                    <button className='btn btn-danger' type='button' onClick={ () => deleteImg(currentImage)}>Eliminar</button>
                                ]}
                            />
                        )}
                    </Col> : null
                }
            </CRow>
            <CRow>
                <Col md={24} xs={24} className="text-center">
                    <img src={retoma} alt="Retomas" />
                </Col>
            </CRow>
            <hr />
            <Form name="basic" form={form} onFinish={onFinish} autoComplete="off" layout='vertical' disabled={estatus}>
                <CRow>
                    <Col md={12} xs={24}>
                        <Form.Item label="Vehículo en venta" name="vehiculo_venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'El vehiculo es requerido!',
                                },
                            ]}>
                            <Select placeholder="Seleccione una opción" options={ vehiclesList }></Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24} >
                        <Form.Item label="Vehículo a retomar"  name="vehiculo_retoma"
                            rules={[
                                {
                                    required: true,
                                    message: 'El vehiculo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Vehiculo a retomar'/>
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col md={12} xs={24} >
                        <Form.Item label="Precio referencia de vehículo en venta" onBlur={changeVehicleVenta} name="prec_ref_veh_venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Precio referencia de vehículo en venta!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }} placeholder='Precio referencia de vehículo en venta'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24} >
                        <Form.Item label="KM vehículo a retomar"  name="km_vehiculo_retoma"
                            rules={[
                                {
                                    required: true,
                                    message: 'El Kilometraje es requerido!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col md={12} xs={24} >
                        <Form.Item label="Promedio de tiempo en venta"  name="promedio_tiempo_venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Promedio de tiempo en venta!',
                                }
                            ]}>
                            <Input type='text' placeholder='Promedio de tiempo en venta' disabled={estatus} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24} >
                        <Form.Item label="Precio referencia de vehículo a retomar" onBlur={ changeVehicle } name="prec_ref_veh_retomar"
                            rules={[
                                {
                                    required: true,
                                    message: 'Precio referencia de vehículo a retomar!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }} placeholder='Precio referencia de vehículo a retomar'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    
                </CRow>
                <CRow>
                    <Col md={12} xs={24}>
                        <Form.Item label="Diferencia neta"  name="diferencia_neta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Diferencia neta!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }} placeholder='Diferencia neta'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24} >
                        <Form.Item label={"Link referencia tucarro - Categoria: "+categoria}  name="link_ref_tucarro" style={{ marginBottom: 0}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Link referencia tucarro!',
                                }
                            ]}>
                            <Input type='text' placeholder='Link referencia tucarro' />                            
                        </Form.Item>
                        <a href={linkTucarro} target='_blank'>Ver referencias</a>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col md={8} xs={24}>
                        <Form.Item label="Precio estimado de venta"  onBlur={ changePEVenta } name="precio_estimado_venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Precio estimado de venta!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                placeholder='Precio estimado de venta'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Utilidad esperada"  name="utilidad_esperada"
                            rules={[
                                {
                                    required: true,
                                    message: 'Utilidad esperada!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                placeholder='Utilidad esperada'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Rentabilidad"  onBlur={ changeVenta } name="rentabilidad"
                            rules={[
                                {
                                    required: true,
                                    message: 'Rentabilidad!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace('%', '')}
                                style={{ width: '100%' }}
                                placeholder='Rentabilidad'
                            />
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col md={24} xs={24} >
                        <Form.Item label="Observaciones"  name="observaciones">
                            <TextArea rows={4}  placeholder='Observaciones' />
                        </Form.Item>
                    </Col>          
                </CRow>
                <CRow>
                    <Col xs={24} span={24} id='drag_ini'>
                        <Form.Item label="Fotos adicionales del vehiculo a retomar"  name="fotos">
                            <ImageUploading
                                name="fotos"
                                multiple
                                value={fileList}
                                dataURLKey="data_url">
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <button type='button'
                                            style={isDragging ? { color: 'red' } : undefined}
                                            onClick={onImageUpload}
                                            className="btn_upload"
                                            {...dragProps} >
                                            Haga clic o Suelte aquí
                                        </button>
                                        &nbsp;
                                        <button className='btn_dd' onClick={onImageRemoveAll} disabled={ imageList.length === 0}>Remover todas las imágenes</button>
                                        <div className='imgList'>
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image['data_url']} alt="" width="100" />
                                                    <div className="image-item__btn-wrapper">
                                                        <button className='btn_dd' onClick={() => onImageUpdate(index)}>Actualizar</button>
                                                        <button className='btn_dd' onClick={() => onImageRemove(index)}>Remover</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </ImageUploading> 
                        </Form.Item>
                    </Col>
                </CRow>
                {
                     ((authUser().email.includes('roberth.ortiz') || authUser().email.includes('santiago.guerrero') || authUser().email.includes('carlos.duarte'))) ?
                     <CRow className='mt-4 mb-4'>
                        <Col span={24}  md={8} xs={8} className='text-end'>
                            <Form.Item>
                                <button type='button' className='btn btn-success' onClick={ () => buttonClick('aprobar') }>{ aprobarButton }</button>
                            </Form.Item>
                        </Col>
                        <Col span={24}  md={8} xs={8} className='text-center'>
                            <Form.Item>
                                <button type='button' className='btn btn-danger' onClick={ () => buttonClick('rechazar') }>{ rechazarButton }</button>
                            </Form.Item>
                        </Col>
                        <Col span={24}  md={8} xs={8} className='text-start'>
                            <Form.Item>
                                <button type='button' className='btn btn-warning' 
                                onClick={ () => buttonClick('modificar') }>MODIFICAR</button>
                            </Form.Item>
                        </Col>
                    </CRow> : null
                }
                {
                    (!authUser().email.includes('roberth.ortiz') && !authUser().email.includes('santiago.guerrero') && !authUser().email.includes('carlos.duarte')) ? 
                   <CRow>
                        <Col span={24}  md={24} xs={24} className='text-center'>
                            <Form.Item>
                                <button type='submit' className='btn btn-primary' >Actualizar retoma</button>
                            </Form.Item>
                        </Col>
                    </CRow> : null
                }
                
            </Form>
        </>
    )
}

export default EditarRetoma;