import API from "./Config";
import ENDPOINTS from "./Enpoints";

const ReporteService = {
    getAll: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.REPORTE)
        .then(
            res => res.data
          )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    })
}

export default ReporteService;