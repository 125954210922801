import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { PlusOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Button, Col, Row, Modal, Upload } from 'antd';
import { CContainer, CRow } from '@coreui/react';
import TextArea from 'antd/es/input/TextArea';
import DriverService from 'src/services/Driver';
import VehiculosService from 'src/services/VehiculosService';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Compressor from 'compressorjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthUser } from 'react-auth-kit';

const { Dragger } = Upload;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

const EditSol = () => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [photosIni, setPhotosIni] = useState([]);
    const [photosEnd, setPhotosEnd] = useState([]);
    const [index, setIndex] = useState(-1);
    var currentImage = photosIni[index];
    const nextIndex = (index + 1) % photosIni.length;
    const nextImage = photosIni[nextIndex] || currentImage;
    const prevIndex = (index + photosIni.length - 1) % photosIni.length;
    const prevImage = photosIni[prevIndex] || currentImage;
    const handleCancel = () => setPreviewOpen(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const { id } = useParams();
    const [traslado, setTraslado] = useState({
        placa: 'asasas',
        km: 50000,
        transporte: '',
        costo: '',
        direccion_recepcion: '',
        direccion_entrega: '',
        detalles: '',
        estado: ''
    });
    const [direcciones, setDirecciones] = useState([]);
    const [errorPhotos, setErrorPhotos] = useState('');
    const [showLot, setShowLot] = useState(false);
    const navigate = useNavigate();
    const authUser = useAuthUser();

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const onFinish = async (values) => {
        $('body,html').stop(true,true).animate({
            scrollTop: $('body').offset().top
        },100);

        if(photosEnd.length === 0){
            if(fileList.length < 5){
                setErrorPhotos("Debe agregar al menos 5 fotos del vehículo!!");
            }else{
              let _data = traslado;
              //_data.direccion_entrega = values.direccion_entrega;
              setShowLot(true)
              setDisabledButton(true)
              //let _direccion = {
              //    direccion : values.direccion_entrega,
              //    id_traslado: id
              //}
              //await DriverService.saveDireccion(_direccion).then(resp =>{
                  if(values.direcciones){
                      values.direcciones.forEach(dir => {
                          let _direccion = {
                              direccion : dir,
                              id_traslado: id
                          }
                          DriverService.saveDireccion(_direccion).then(resp =>{})
                      });

                  }
              //})
              var count_fotos = 0;
              await DriverService.update(_data, id).then(async x => {
                let interval = setInterval(async() => {
                  let element = fileList[count_fotos];
                  new Compressor(element.originFileObj, {
                    quality: 0.6,
                    success: (result) => {
                      if(result){
                        const formData = new FormData();
                        formData.append('file', result, "END_"+count_fotos+"_"+id+"_img_"+result.name);
                        VehiculosService.uploadPhotoDriver(formData, "driver_"+id).then(response =>{
                          if(response){
                            let photo = {
                                name: `IMG_END_${id}_${count_fotos}`,
                                url: response.uri,
                                category: 'end',
                                vehicle_driver_id: id
                            }
                            DriverService.savePhoto(photo).then(resp =>{ })
                          }
                        })
                      }
                    }
                  })
                  count_fotos++;
                  if (count_fotos >= fileList.length) {
                    clearInterval(interval);
                    toast.success('Traslado actualizado con éxito', {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: false,
                      pauseOnHover: false,
                      draggable: false,
                      progress: undefined,
                      theme: "light",
                    });
                    setTimeout(() => {
                        setShowLot(false)
                        setDisabledButton(false)
                        navigate('/conductor')
                    }, 3000);
                  }
              }, 3000);
            })
          }
        }else{
            if(values.direcciones){
                setShowLot(true)
                setDisabledButton(true)
                let _direccion = {}
                values.direcciones.forEach(dir => {
                    _direccion = {
                        direccion : dir,
                        id_traslado: id
                    }
                    DriverService.saveDireccion(_direccion).then(resp =>{
                        setTimeout(() => {
                            //setShowLot(false)
                            window.location.reload()
                        }, 3000);
                    })
                });

            }
        }
    };

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getItem();
    }, []);

    const getItem = async () =>{
        await DriverService.get(id).then(data =>{
            let _traslado = traslado;
            _traslado.placa = data.traslado.placa;
            _traslado.km = data.traslado.km;
            _traslado.transporte = data.traslado.transporte;
            _traslado.costo = data.traslado.costo;
            _traslado.detalles = data.traslado.detalles;
            _traslado.estado = data.traslado.estado;
            _traslado.direccion_recepcion = data.direcciones[0].direccion;
            _traslado.direccion_entrega = data.direcciones.length > 1 ? data.direcciones[1].direccion : '';
            setTraslado(_traslado);
            let _images = [];
            data.photosIni.forEach(element => {
                _images.push({
                    src: element.url,
                    original: element.url,
                    width: 500,
                    height: 400,
                    caption: element.name,
                })
            });
            let _images_end = [];
            data.photosEnd.forEach(element => {
                _images_end.push({
                    src: element.url,
                    original: element.url,
                    width: 500,
                    height: 400,
                    caption: element.name,
                })
            });
            setPhotosIni(_images)
            setPhotosEnd(_images_end)
            setDirecciones(data.direcciones)
        })
    }

    const handleClick = (index, item) => {
        currentImage = photosIni[index]
        setIndex(index);
    }
    const beforeUpload = file => {
        if (!["image/jpeg", "image/png"].includes(file.type)) {
            setErrorPhotos(`${file.name} No es un tipo de imagen válido`);
          return null;
        }
        return false;
    };

    const handleChange = ({ fileList }) => setFileList(fileList.filter(file => file.status !== "error"));

    const onRemove = async file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        setFileList(newFileList);
    };

    const finalizar = () => {
        setShowLot(true)
        $('body,html').stop(true,true).animate({
            scrollTop: $('body').offset().top
        },100);
        let data = {
            estado: 'Finalizado',
            id_traslado: id,
            placa: traslado.placa,
            km: traslado.km,
            detalles: traslado.detalles,
            direccion1: direcciones[0].direccion,
            direccion2: direcciones[direcciones.length - 1].direccion
        }
        DriverService.finalizar(data).then(resp =>{
            setTimeout(() => {
                setShowLot(false)
                navigate("/conductor/consultar/"+id);
            }, 3000);
        })
    }

    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);
    return(
        <CContainer>
          <ToastContainer />
            <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" />
            </div>
            <Row>
                <Col span={24} xs={24} className='text-center my-4'>
                    <h1>Traslado</h1>
                </Col>
            </Row>
            <CRow>
                <Col span={12} md={12} xs={24} >
                    <div className='form-group'>
                        <label>Placa</label>
                        <input className='form-control' disabled value={ traslado.placa } />
                    </div>
                </Col>
                <Col span={12} md={12} xs={24} >
                    <div className='form-group'>
                        <label>Kilometraje</label>
                        <input className='form-control' disabled value={ traslado.km } />
                    </div>
                </Col>
                <Col span={12} md={12} xs={24} >
                    <div className='form-group'>
                        <label>Medio de transporte</label>
                        <input className='form-control' disabled value={ traslado.transporte } />
                    </div>
                </Col>
                <Col span={12} md={12} xs={24} >
                    <div className='form-group'>
                        <label>Costo de transporte</label>
                        <input className='form-control' disabled value={ traslado.costo } />
                    </div>
                </Col>
                <Col span={24} md={24} xs={24} >
                    <div className='form-group'>
                        <label>Detalles</label>
                        <textarea className='form-control' disabled value={ traslado.detalles}></textarea>
                    </div>
                </Col>
                {
                    direcciones.length > 1 ?
                    direcciones.map((direccion, index) => (
                        <Col span={24} md={24} xs={24} >
                            <div className='form-group'>
                                <label>Dirección # {index + 1}</label>
                                <textarea className='form-control' disabled value={ direccion.direccion }></textarea>
                            </div>
                        </Col>
                    ))
                    : null
                }
                <Col span={24}>
                    <Form name="basic"
                        onFinish={onFinish}
                        layout='vertical' initialValues={{ traslado }}>
                        <CRow>
                            <Col span={24} md={24} xs={24} >
                                <Form.List name="direcciones">
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item required={false} key={field.key} label="Dirección adicional">
                                            <Form.Item
                                              {...field}
                                              validateTrigger={['onChange', 'onBlur']} label="Dirección adicional"
                                              rules={[
                                                {
                                                  required: true,
                                                  whitespace: true,
                                                  message: "Por favor agregue una dirección o borre este campo",
                                                },
                                                {
                                                    min: 6,
                                                    message: 'La dirección ingresada no es válida'
                                                }
                                              ]}
                                              noStyle
                                            >
                                              <TextArea></TextArea>
                                            </Form.Item>
                                            {fields.length > 0 ? (
                                              <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                              />
                                            ) : null}
                                          </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{ float: 'right' }}
                                                icon={<PlusOutlined />}
                                            >
                                                Agregar otra dirección
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                                </Form.List>
                            </Col>
                        </CRow>
                        <CRow>
                            <Col span={12} md={12} xs={24} >
                                <label>Fotos del vehículo al recibirlo</label>
                                <Gallery
                                    images={photosIni}
                                    onClick={handleClick}
                                    enableImageSelection={false}
                                />
                                {!!currentImage && (
                                    /* @ts-ignore */
                                    <Lightbox
                                        mainSrc={currentImage.original}
                                        imageTitle={currentImage.caption}
                                        mainSrcThumbnail={currentImage.src}
                                        nextSrc={nextImage.original}
                                        nextSrcThumbnail={nextImage.src}
                                        prevSrc={prevImage.original}
                                        prevSrcThumbnail={prevImage.src}
                                        onCloseRequest={handleClose}
                                        onMovePrevRequest={handleMovePrev}
                                        onMoveNextRequest={handleMoveNext}
                                    />
                                )}
                            </Col>
                            <Col span={12} md={12} xs={24} >
                                <label>Fotos del vehículo al entregarlo</label>
                                {
                                    photosEnd.length === 0 ?
                                    <>
                                        <Dragger
                                            listType="picture-card"
                                            fileList={fileList}
                                            beforeUpload={beforeUpload}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            onRemove={onRemove}
                                            multiple={true}
                                            >
                                            <div className="uploadIcon">
                                                <UploadOutlined />
                                            </div>
                                            <div className="uploadText">
                                                <p>Arrastra las imágenes aquí</p>
                                                <p>o</p>
                                                <p>Haz clic!</p>
                                            </div>
                                        </Dragger>
                                        <p name="error" className="error">{ errorPhotos }</p>
                                    </> :
                                    <>
                                        <Gallery
                                            images={photosEnd}
                                            onClick={handleClick}
                                            enableImageSelection={false}
                                        />
                                        {!!currentImage && (
                                            /* @ts-ignore */
                                            <Lightbox
                                                mainSrc={currentImage.original}
                                                imageTitle={currentImage.caption}
                                                mainSrcThumbnail={currentImage.src}
                                                nextSrc={nextImage.original}
                                                nextSrcThumbnail={nextImage.src}
                                                prevSrc={prevImage.original}
                                                prevSrcThumbnail={prevImage.src}
                                                onCloseRequest={handleClose}
                                                onMovePrevRequest={handleMovePrev}
                                                onMoveNextRequest={handleMoveNext}
                                            />
                                        )}
                                    </>
                                }
                            </Col>
                        </CRow>

                        <CRow>
                            <Col span={24} md={24} xs={24} className='text-center my-3'>
                                <div className='d-flex justify-content-center'>
                                    <Form.Item>
                                        <button className='btn btn-secondary ms-2' type="submit" disabled={disabledButton}>
                                            {
                                                photosEnd.length > 0 ?
                                                'Adicionar dirección' : 'Actualizar traslado'
                                            }
                                        </button>
                                    </Form.Item>
                                    {
                                        direcciones.length > 1 && photosEnd.length > 0 ?
                                        <Form.Item>
                                            <button type="button" onClick={ finalizar } className='btn btn-primary ms-2'>Finalizar traslado</button>
                                        </Form.Item> : null
                                    }
                                </div>
                            </Col>
                        </CRow>
                    </Form>
                </Col>
            </CRow>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </CContainer>

    )
}

export default EditSol;
