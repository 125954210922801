import { CCol, CRow, CButton } from '@coreui/react';
import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from "react-router-dom";
import VehiculosService from "src/services/VehiculosService";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { Card } from './Card.js'
import { useNavigate  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Order = () =>{
    var { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({
        make: '',
        year: '',
        model: '',
        version: '',
        vehicle_type: '',
        vehicle_body_type: '',
        transmission: '',
        fuel_type: '',
        color: '',
        kilometers: '',
        price: 0,
        status: '',
        title: '',
        plate: '',
        img_main: '',
        pictures: '',
        published: 0
    });
    const [photos, setPhotos] = useState([]);
    const moneyFormat = (value) => new Intl.NumberFormat().format(value)+" COP";
    const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
    const [showLoad, setShowLoad] = useState(false);
    const [mainImage, setMainImage] = useState(null);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setPhotos((prevCards) =>
          update(prevCards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevCards[dragIndex]],
            ],
          }),
        )
    }, [])

    const renderCard = useCallback((card, index) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.url}
            moveCard={moveCard}
          />
        )
    }, [])


    useEffect(() => {
        getItem();
    }, []);

    const getItem = () =>{
        VehiculosService.get(id).then(resp =>{
            setItem(resp.response);
            let photos = resp.response.pictures.split(',');
            let photosArray = [];
            photos.forEach((element, index) => {
                photosArray.push({ id: index + 1, url: element })
            });
            setPhotos(photosArray);
        }).catch(error => console.log(error));
    }

    const actualizarPhoto = async () =>{
        const data = new FormData() 
        data.append('file', mainImage);                
        data.append('isPortada', true);                
        await VehiculosService.uploadPhoto(data, "auto_"+id, true).then(response =>{
            toast.success('Imágen principal actualizada con éxito', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {                
                window.location.reload();
            }, 5000);
        })
    }

    const actualizar = () =>{
        setShowLoad(true);
        let _data = {
            main: item.img_main,
            pictures: ''
        }
        let _pictures = "";
        photos.forEach((element) =>{
            _pictures += element.url+",";
        })
        _data.pictures = _pictures.slice(0, -1);
        VehiculosService.saveImagens(_data, id).then(response =>{
            toast.success('Imagenes ordenados con éxito', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {  getItem() ; navigate("/vehiculos/");}, 2000);
        })
    }

    return(<>
        <CRow>
            <ToastContainer />
            <CCol className='text-center' xs={12}>
                <h2 className='mb-4'>Ordenar vehículo</h2>
            </CCol>
        </CRow>
        <CRow className='my-4'>
            <CCol md={8} xs={12}>
                <h4>Información del vehículo</h4>
                <ul>
                    <li><b>Auto: </b>{ item.title }</li>
                    <li><b>Tipo</b> { item.vehicle_type }</li>
                    <li><b>carrocería: </b>{ item.vehicle_body_type }</li>
                    <li><b>Transmisión: </b> { item.transmission }</li>
                    <li><b>Combustible: </b> { item.fuel_type }</li>
                    <li><b>Color: </b>{ item.color }</li>
                    <li><b>Kilometraje: </b>{ kmFormat(item.kilometers) }</li>
                    <li><b>Precio:</b> { moneyFormat(item.price) }</li>
                    <li><b>Estado: </b>: { item.status }</li>
                </ul>
            </CCol>
            <CCol md={4} xs={12}>
                <img className='d-block w-100' src={item.img_main} />
                <input type='file' name='mainImage' onChange={(e) => setMainImage(e.target.files[0])} />
                <button type='button' className='btn btn-primary' onClick={ actualizarPhoto }>Actualiza foto principal</button>
            </CCol>
        </CRow>
        <CRow>
            <CCol md={12} className='text-center'>
                <h4>Ordenar Fotos</h4>
                {
                    photos.length > 0 ?
                    <DndProvider backend={HTML5Backend}>
                        <CRow>
                        {
                            photos.map((card, i) => renderCard(card, i))
                        }
                        </CRow>   
                    </DndProvider> : ''
                }
                <CButton className='btn btn-lg' onClick={actualizar}>Ordenar</CButton>
            </CCol>
        </CRow>

    </>)
}

export default Order;