import React, { useState, useEffect } from 'react'
import { CCol, CRow, CButton } from "@coreui/react";
import VehiculosService from "src/services/VehiculosService";
import { useParams } from "react-router-dom";
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate  } from "react-router-dom";
import { Puff } from 'react-loader-spinner';

const NewEdit = () =>{
    const { id } = useParams();
    const [file, setFile] = useState([]);
    const [mainImage, setMainIMage] = useState();
    const [showButton, setShowButton] = useState(false)
    const [showLoad, setShowLoad] = useState(false);
    const [item, setItem] = useState({
        response: {},
        photos: []
    })
    const [photos, setPhotos] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        getItem();
    }, []);

    const deleteImg = (_index, _image) =>{
        let _new_photos = photos;
        _new_photos.splice(_index, 1);
        setPhotos(_new_photos);

        let _name = _image.split("/");
        let data ={
            folder_name: `auto_${id}`,
            file_name: _name[_name.length - 1],
        }
        setShowLoad(true)
        VehiculosService.deletePhoto(data).then(resp =>{
            //Update photos on database
            let _data = {
                main:  item.response.img_main,
                pictures:  _new_photos
            } 
            
            VehiculosService.saveImagens(_data, id).then(response =>{
                
                toast.success('Imagen eliminada con éxito', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    getItem();
                    setShowLoad(false)
                    navigate("/vehiculos/eliminar/"+id);
                    //
                }, 5000);  
            })
        })
    }

    const cargarFoto = async () =>{
        let _data = {
            main: '',
            pictures: ''
        }  
        _data.main = item.response.img_main;
        _data.pictures = item.response.pictures+",";
       // data.append('file', mainImage);     
        setShowLoad(true)          
        mainImage.forEach(async (element, index) => {
            //console.log(element)
            const data = new FormData() 
            data.append('file', element); 
            await VehiculosService.uploadPhoto(data, "auto_"+id).then(async response =>{            
                _data.pictures += response.uri+",";              
            })
            if((index + 1) == mainImage.length){
                _data.pictures = _data.pictures.slice(0, -1);
                await VehiculosService.saveImagens(_data, id).then(response2 =>{ 
                    setFile('')
                    setShowButton(false)
                    document.getElementById("upload-image-adi").value = "";                
                    toast.success('Imagen agregada con éxito', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        getItem();
                        setShowLoad(false)
                        navigate("/vehiculos/");
                        //getItem()
                    }, 2000);               
                })
            }
        })
    }

    const getItem = () =>{
        VehiculosService.get(id).then(resp =>{
            let _item = item;
            _item.response = resp.response;
            let _photos = resp.response.pictures.split(',');
            setItem(_item);
            setPhotos(_photos);
        })
    }

    const handleChange = (event) =>{
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        let photos = [];
        chosenFiles.forEach(element => {
            photos.push(URL.createObjectURL(element));
        });
        setFile(photos);
        setMainIMage(chosenFiles);
        setShowButton(true)
    }
    return (
        <>
            <CRow>
                <ToastContainer />                
                <CCol className='text-center' xs={12}>
                    <h2 className='mb-4'>Editar vehículo</h2>
                </CCol>
            </CRow>

            <CRow>
                {/* Fotos */}
                <CCol xs={12} md={12}>                    
                    {
                        photos.length > 0 ? 
                        (
                            photos.map(
                                (image, index) =>(
                                    <div key={index} className='img-inline'>
                                        <img src={image} className="img-thumbnail" alt="Vehículo" />
                                        <div className='des' onClick={ () => deleteImg(index, image) }>
                                            <CIcon icon={icon.cilDelete} className="icon-delete"></CIcon>
                                        </div>                                        
                                    </div>
                                )
                            )                            
                        )
                        :
                        ''
                    }
                    <hr />
                    <Puff
                        height="80"
                        width="80"
                        radius={1}
                        color="#c14717"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={ showLoad }
                    />
                    <div className="adicional-foto mb-4">
                        <h4>Agregar foto adicional</h4>
                        <input type="file" onChange={handleChange} id="upload-image-adi" multiple />
                        <div className='d-flex'>
                        {
                            file.length > 0 ? file.map((ele, index) =>(
                                <img width={200} className='imgInline' src={ele} key={'img'+index} />    
                            )) : ''
                        }
                        </div>
                        {
                            showButton ? (<button className='btn btn-primary' onClick={ cargarFoto }>Subir</button>) :  ''
                        }                           
                    </div>
                    <hr />
                </CCol>
                
            </CRow>
        </>
    )
}

export default NewEdit;