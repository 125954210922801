import React ,{ useEffect, useState } from 'react';
import { CCol, CRow, CFormSelect, CFormLabel, CButton, CFormInput } from '@coreui/react'
import MakeService from 'src/services/MakeService';
import QuoteService from 'src/services/QuoteService';
import ModelService from 'src/services/ModelService';
import YearService from 'src/services/YearService';
import VersionService from 'src/services/VersionService';
import BitacoraService from 'src/services/Bitacora';
import { useAuthUser } from 'react-auth-kit';
import { Puff } from 'react-loader-spinner';

const Register = () =>{
    const [makes, setMakes] = useState([]);
    const [make, setMake] = useState(0);
    const [makeError, setErrorMake] = useState("");
    const [years, setYears] = useState([]);
    const [year, setYear] = useState(0);
    const [yearError, setErrorYear] = useState("");
    const [models, setModels] = useState([]);
    const [model, setModel] = useState(0);
    const [modelError, setErrorModel] = useState("");
    const [versions, setVersions] = useState([]);
    const [version, setVersion] = useState(0);
    const [versionError, setErrorVersion] = useState("");
    const [status, setStatus] = useState("");
    const [statusError, setErrorStatus] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setErrorPhone] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setErrorEmail] = useState("");
    const [names, setNames] = useState("");
    const [namesError, setErrorNames] = useState("");
    const [km, setKm] = useState("");
    const [errorKm, setErrorKm] = useState("");
    const [precioOfertado, setPrecioOfertado] = useState("");
    const moneyFormat = (value) => "$ "+new Intl.NumberFormat().format(value);
    const authUser = useAuthUser();
    const [showLoad, setShowLoad] = useState(false);

    useEffect(() => {
        getMakes();
    }, [])

    const getMakes = () =>{
        MakeService.getAll()
        .then(data => {
            setMakes(data.data);
        })
        .catch( error => console.log(error));
    }
    const getYears = (event) =>{
        if(event.target.value !== ""){
            setMake(event.target.value);
            YearService.getYearByMake(event.target.value)
            .then(data => {
                setYears(data.data);
                setErrorMake("");
            })
            .catch( error => console.log(error));
        }
    }
    const getModels = (event) =>{
        if(event.target.value !== ""){
            setYear(event.target.value);
            ModelService.getModelsByYear(event.target.value)
            .then(data => {
                setModels(data.data);
                setErrorYear("");
            })
            .catch( error => console.log(error));
        }
    }
    const getVersions = (event) =>{
        if(event.target.value !== ""){
            setModel(event.target.value);
            VersionService.getVersionsByModel(event.target.value)
            .then(data => {
                setVersions(data.data);
                setErrorModel("");
            })
            .catch( error => console.log(error));
        }
    }
    const saveVersion = (event) =>{
        if(event.target.value !== ""){
            setVersion(event.target.value);
            setErrorVersion("");
        }
    }
    const saveStatus = (event) =>{
        if(event.target.value !== ""){
            setStatus(event.target.value);
            setErrorStatus("");
        }
    }
    const saveEmail = (event) =>{
        if(event.target.value !== ""){
            setEmail(event.target.value);
            setErrorEmail("");
        }
    }
    const savePhone = (event) =>{
        if(event.target.value !== ""){
            setPhone(event.target.value);
            setErrorPhone("");
        }
    }
    const saveNames = (event) =>{
        if(event.target.value !== ""){
            setNames(event.target.value);
            setErrorNames("");
        }
    }
    const saveKm = (event) =>{
        if(event.target.value !== ""){
            setKm(event.target.value);
            setErrorKm("");
        }
    }
    const isValidInput = (_input, _type) =>{
        let isValid = false;
        switch (_type) {
            case "text":
                if(_input.length > 0)
                    isValid = true;
                break;
            case "number":
                if(_input > 0)
                    isValid = true;
                break;
            case "phone":
                if(_input.length > 0 && /^3[0-9]{9}/i.test(_input))
                    isValid = true;
                break;
            case "email":
                if(_input.length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_input))
                    isValid = true;
                break;
            case "check":
                if(_input)
                    isValid = true;
                break;
            case "names":
                if(_input.length > 0 && /^(?:\b\w+\b[\s\r\n]*){2}$/i.test(_input))
                    isValid = true;
                break;
        }
        return isValid;
    }

    const cleanFields = () =>{
        setMake("");
        setYear("");
        setModel("");
        setVersion("");
        setNames("");
        setPhone("");
        setEmail("");
        setKm("");
        setStatus("");
    }

    const saveData = () =>{
        !isValidInput(km, "number") ? setErrorKm("El km es requerido.") : setErrorKm("");
        !isValidInput(status, "text") ? setErrorStatus("El estado del vehículo es requerido.") : setErrorStatus("");
        !isValidInput(phone, "text") ? setErrorPhone("El celular es requerido.") : setErrorPhone("");
        !isValidInput(email, "text") ? setErrorEmail("El correo electrónico es requerido.") : setErrorEmail("");
        !isValidInput(names, "text") ? setErrorNames("Los nombres y apellidos son requeridos.") : setErrorNames("");
        !isValidInput(version, "number") ? setErrorVersion("La versión es requerida.") : setErrorVersion("");
        !isValidInput(model, "number") ? setErrorModel("El modelo es requerido.") : setErrorModel("");
        !isValidInput(make, "number") ? setErrorMake("La marca es requerida.") : setErrorMake("");
        !isValidInput(year, "number") ? setErrorYear("El año es requerido.") : setErrorYear("");
        !isValidInput(phone, "phone") ? setErrorPhone("El celular ingresado no es correcto.") : setErrorPhone("");
        !isValidInput(email, "email") ? setErrorEmail("Ingrese un correo electrónico válido.") : setErrorEmail("");
        !isValidInput(names, "names") ? setErrorNames("Debe ingresar nombre y apellido.") : setErrorNames("");

        if(isValidInput(km, "number") && isValidInput(phone, "text") && isValidInput(email, "text") && isValidInput(names, "text")
            && isValidInput(version, "number") && isValidInput(model, "number") && isValidInput(make, "number") && isValidInput(year, "number")
            && isValidInput(email, "email") && isValidInput(phone, "phone") && isValidInput(names, "names") && isValidInput(status, "text") ) {

            let data = {
                names: names,
                phone: phone,
                email: email,
                model: models.find(x => x.id === parseInt(model)).name,
                make: makes.find(x => x.id ===  parseInt(make)).name,
                version: versions.find(x => x.id ===  parseInt(version)).name, 
                year: years.find(x => x.id ===  parseInt(year)).name,
                version_id: parseInt(version),
                km: parseInt(km),
                status: status
            }
            setShowLoad(true);
            QuoteService.saveAlfred(data).then(resp_alfred =>{
                let _make = data.make.replaceAll(' ', '-');
                let _url = "https://carros.tucarro.com.co/"+_make.toLowerCase()+"/"+models.find(x => x.id === parseInt(model)).sname.toLowerCase()+"/"+data.year+"_OrderId_PRICE_ITEM*CONDITION_2230581_NoIndex_True";
                let _data = {
                    "url": _url,
                    "category":  versions.find(x => x.id ===  parseInt(version)).category,
                    "status": status,
                    "keys":  versions.find(x => x.id ===  parseInt(version)).key,
                    "km": km,
                    "year": data.year,
                    "make": _make
                }
        
                QuoteService.getTuCarro(_data)
                .then(resp_tc => {
                    setPrecioOfertado(resp_tc.respuesta.offer_price)
                    _data = {
                        make: data.make,
                        year: data.year,
                        model: data.model,
                        version: data.version,
                        category:  versions.find(x => x.id ===  parseInt(version)).category,
                        url: _url,
                        prom_tucarro: resp_tc.respuesta.prom_original,
                        price_ofert: resp_tc.respuesta.offer_price,
                        status: status,
                        email: authUser().email,
                        origin: 'alfred',
                        km: km
                    }
                    //Guardamos en bitacora
                    BitacoraService.save(_data).then(resp =>{
                        //Actualizar el quote resp_alfred
                        console.log(resp_alfred.id);
                        QuoteService.updateAlfred({id: resp_alfred.id, price: resp_tc.respuesta.offer_price}).then(x => console.log(x)).catch(error => console.log(error));
                        cleanFields();
                        setShowLoad(false);
                    })
                    .catch(error => console.log(error));
                })
                .catch(error => console.log(error))
            })
        }
    }

    return(<>
        <CRow>
            <CCol className='text-center mb-4' sm={12}>
                <h1>Registro de vehículo</h1>
            </CCol>
            {/* <CCol>
                <div className='text-center ms'>
                    <h4>¡Alerta, alerta! Nuestra calculadora de precios de vehículos se comió demasiados números y ahora tiene una indigestión matemática. Pero no te preocupes, estamos trabajando en su recuperación, nuestra calculadora volverá pronto con más energía y lista para ayudarnos a hacer cálculos más rápidos y precisos.</h4>
                </div>
               
            </CCol> */}
            <CCol sm={12}>
                <CRow>
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Selecione la marca</CFormLabel>
                            <CFormSelect aria-label="Seleccione la marca" onChange={getYears} name="make">
                                <option value="">Seleccione</option>
                                {
                                    makes.length > 0 ? makes.map(
                                    (item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                    ): ''
                                }
                            </CFormSelect>
                            <p className='small'>{ makeError }</p>
                        </div>
                    </CCol>
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Selecione el año</CFormLabel>
                            <CFormSelect aria-label="Seleccione el año" onChange={getModels}>
                                <option value="">Seleccione</option>
                                {
                                    years.length > 0 ? years.map(
                                    (item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                    ): ''
                                }
                            </CFormSelect>
                            <p className='small'>{ yearError }</p>
                        </div>
                    </CCol>
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Selecione el modelo</CFormLabel>
                            <CFormSelect aria-label="Seleccione el modelo" onChange={getVersions}>
                                <option value="">Seleccione</option>
                                {
                                    models.length > 0 ? models.map(
                                    (item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                    ): ''
                                }
                            </CFormSelect>
                            <p className='small'>{ modelError }</p>
                        </div>
                    </CCol>
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Selecione la versión</CFormLabel>
                            <CFormSelect aria-label="Seleccione la versión" onChange={saveVersion}>
                                <option value="">Seleccione</option>
                                {
                                    versions.length > 0 ? versions.map(
                                    (item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                    ): ''
                                }
                            </CFormSelect>
                            <p className='small'>{ versionError }</p>
                        </div>
                    </CCol>                    
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Nombre(s) y Apellido(s)</CFormLabel>
                            <CFormInput onChange={saveNames}></CFormInput>
                            <p className='small'>{ namesError }</p>
                        </div>
                    </CCol>
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Teléfono</CFormLabel>
                            <CFormInput  onChange={savePhone}></CFormInput>
                            <p className='small'>{ phoneError }</p>
                        </div>
                    </CCol>
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Correo</CFormLabel>
                            <CFormInput onChange={saveEmail}></CFormInput>
                            <p className='small'>{ emailError }</p>
                        </div>
                    </CCol>
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Kilometraje</CFormLabel>
                            <CFormInput type='number' onChange={saveKm}></CFormInput>
                            <p className='small'>{ errorKm}</p>
                        </div>
                    </CCol>
                    <CCol sm={6}>
                        <div className='form-group'>
                            <CFormLabel>Selecione el estado del vehículo</CFormLabel>
                            <CFormSelect aria-label="Seleccione el estado del vehículo" onChange={saveStatus}>
                                <option value="">Seleccione</option>
                                <option value="Excelente">Excelente</option>
                                <option value="Muy Bueno">Muy Bueno</option>
                                <option value="Bueno">Bueno</option>
                                <option value="Semi Bueno">Semi-Bueno</option>
                                <option value="Regular">Regular</option>
                            </CFormSelect>
                            <p className='small'>{ statusError }</p>
                        </div>
                    </CCol>
                    <CCol sm={6}>                        
                        {
                            precioOfertado !== '' ? 
                            (
                                <div className='form-group'>
                                    <CFormLabel>Precio ofertado</CFormLabel>
                                    <h3>{ moneyFormat(precioOfertado) }</h3>
                                </div>
                            ): ''
                        }
                        <Puff
                            height="80"
                            width="80"
                            radius={1}
                            color="#c14717"
                            ariaLabel="puff-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={ showLoad }
                        />
                    </CCol>
                    <CCol sm={12}>
                        <div className='form-group text-center'>
                            <CButton className='btn btn-primary' onClick={saveData}>Guardar</CButton>
                        </div>
                    </CCol>                    
                </CRow>
            </CCol>
        </CRow>
    </>)
}

export default Register