import { CCol, CRow } from '@coreui/react';
import React ,{ useEffect, useState } from 'react'
import BitacoraService from 'src/services/Bitacora'
import { useAuthUser } from 'react-auth-kit';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';

const Bitacora = () => {
  const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
  const moneyFormat = (value) => new Intl.NumberFormat().format(value)+" COP";
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState(null);
  const authUser = useAuthUser();

  useEffect(() => {
    getData();
    addLocale('es', {
      startsWith: 'Inicia con',
      contains: 'Contiene'
    })
  }, [])
 
  const getData = () =>{
    BitacoraService.getAll().then(resp =>{
      let _data = resp.data;
      if(authUser().rol === 2){
        setData(_data.filter(x => x.origin === 'alfred'));
      }else{
        setData(_data);
      }
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
    })
    .catch(error => console.log(error));
  }
  const urlBodyTemplate = (rowData) => {
    return <a href={ rowData.url } target='_blank'>tucarro.com.co</a>;
  };
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between text-end">
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <input type="search" className='form-control' onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
        </span>
    </div>
  );

  const kmBodyTemplate = (rowData) => {
    return kmFormat(rowData.km);
  };
  const priceOfertBodyTemplate = (rowData) => {
    return moneyFormat(rowData.price_ofert);
  };
  const promTucarroBodyTemplate= (rowData) => {
    return moneyFormat(rowData.prom_tucarro);
  };
  return(
    <>
      <CRow>
        <CCol xs={12} className='text-center mb-4'>
            <h2>Bitácora</h2>
        </CCol>
      </CRow>

      <CRow>
        <DataTable size={'small'} filterLocale={ 'es' } loading={ isLoading } emptyMessage={ "No se han encontrado resultados" } value={data} tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100, 0]}
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"  globalFilter={globalFilter} header={header}>
            <Column field="created_at" header="Fecha" sortable filter></Column>
            <Column field="vehicle" header="Vehículo" sortable  filter></Column>
            <Column field="km" body={ kmBodyTemplate } header="KM"  sortable  filter></Column>
            <Column field="category" header="Categoria" sortable filter></Column>
            <Column field="prom_tucarro" body={ promTucarroBodyTemplate } header="TuCarro" sortable  filter></Column>
            <Column field="pdf" body={ urlBodyTemplate } header="PDF" sortable filter></Column>
            <Column field="price_ofert" body={ priceOfertBodyTemplate } header="Oferta" sortable  filter></Column>
            <Column field="names" header="Usuario" sortable filter></Column>
        </DataTable>
      </CRow>
    </>
  )
}

export default Bitacora
