import API from "./Config";
import ENDPOINTS from "./Enpoints";

const UploadService = {
	save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.UPLOADS, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    })
}

export default UploadService;