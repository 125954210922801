import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Input, Row, Select, Modal } from 'antd';
import { CContainer, CRow } from '@coreui/react';
import TextArea from 'antd/es/input/TextArea';
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import { useNavigate } from "react-router-dom";
import DriverService from 'src/services/Driver';
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthUser } from 'react-auth-kit';

const Create = () => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [showLot, setShowLot] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const navigate = useNavigate();
    const handleCancel = () => setPreviewOpen(false);
    const authUser = useAuthUser();

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
    }, []);

    const onFinish = async (values) =>  {
        $('body,html').stop(true,true).animate({
            scrollTop: $('body').offset().top
        },100);
        setShowLot(true)
        setDisabledButton(true)
        let data = {
            kilometraje: values.km,
            placa: values.placa,
            transporte: values.transporte ? values.transporte : 'Ninguno',
            costo: values.costo ? values.costo : 0,
            detalles: values.detalles ? values.detalles : 'Ninguno',
            estado: 'En ruta',
            direccion: values.direccion_recepcion,
            destino: values.direccion_destino,
            user_id: authUser().id
        };
        await DriverService.save(data).then(rr =>{
            toast.success('Traslado creado con éxito', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            let dataDriver = {
                names: authUser().names,
                plate: values.placa,
                phone: authUser().phone
              }
            //DriverService.sendMsg(dataDriver).then(resp => console.log(resp))
            setTimeout(() => {
                setShowLot(false)
                setDisabledButton(false)
                navigate('/conductor/solicitud/fotos/'+rr.id)
            }, 3000);
        })
    };

    const onFinishFailed = (values) => {
        console.log('Failed:', values);
    };

    return(
        <CContainer>
            <ToastContainer />
            <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" />
            </div>
            <Row>
                <Col span={24} md={24} xs={24} className='text-center my-4'>
                    <h1>Traslado</h1>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form name="basic"
                        onFinish={onFinish} onFinishFailed={onFinishFailed} defaultValue={{ transporte: '' }}
                        autoComplete="off" layout='vertical'>
                        <CRow>
                            <Col md={12} xs={24}>
                                <Form.Item label="Placa" name="placa"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'La placa es requerida!',
                                        },
                                        {
                                            max: 6,
                                            message: 'La placa debe contener 6 caracteres'
                                        },
                                        {
                                            min: 6,
                                            message: 'La placa debe contener 6 caracteres'
                                        }
                                    ]}>
                                    <Input placeholder='Placa del vehículo' />
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24} >
                                <Form.Item label="Kilometraje"  name="km"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'El kilometraje es requerido!',
                                        }
                                    ]}>
                                    <Input type='number' placeholder='Kilometraje del vehículo' />
                                </Form.Item>
                            </Col>
                        </CRow>
                        <CRow>
                            <Col md={12} xs={24} >
                                <Form.Item label="Medio de transporte"  name="transporte" >
                                    <Select placeholder="Seleccione una opción" options={[
                                        { value: 'A pie', label: 'A pie' },
                                        { value: 'Motocicleta', label: 'Motocicleta' },
                                        { value: 'Vehículo', label: 'Vehículo' }
                                      ]}>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24}>
                                <Form.Item label="Costo de transporte"  name="costo" >
                                    <Input placeholder='Ingrese el costo'/>
                                </Form.Item>
                            </Col>
                        </CRow>
                        <CRow>
                            <Col md={24} xs={24}>
                                <Form.Item label="Dirección de recepción"  name="direccion_recepcion"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'La dirección de recepción es requerida!',
                                        },
                                        {
                                            min: 6,
                                            message: 'La dirección ingresada no es válida'
                                        }
                                    ]}>
                                    <TextArea placeholder='¿Donde esta recibiendo el vehículo?'></TextArea>
                                </Form.Item>
                            </Col>
                        </CRow>
                        <CRow>
                            <Col md={24} xs={24}>
                                <Form.Item label="Dirección de destino"  name="direccion_destino"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'La dirección de destino es requerida!',
                                        },
                                        {
                                            min: 6,
                                            message: 'La dirección ingresada no es válida'
                                        }
                                    ]}>
                                    <TextArea placeholder='¿Donde va a entregar el vehículo?'></TextArea>
                                </Form.Item>
                            </Col>
                        </CRow>
                        <CRow>
                            <Col md={24} xs={24}>
                                <Form.Item label="Detalles del vehículo"  name="detalles" >
                                    <TextArea></TextArea>
                                </Form.Item>
                            </Col>
                        </CRow>
                        <CRow>
                            <Col span={24}  md={24} xs={24} className='text-center'>
                                <Form.Item>
                                    <Button type='primary'  htmlType="submit" disabled={disabledButton}>Guardar traslado</Button>
                                </Form.Item>
                            </Col>
                        </CRow>
                    </Form>
                </Col>
            </Row>
        </CContainer>

    )
}

export default Create;
