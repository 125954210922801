import API from "./Config";
import ENDPOINTS from "./Enpoints";

const NotificacionesService = {
    getAll: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.NOTIFICACIONES)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    get: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.NOTIFICACIONES+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    delete: (id) => new Promise((resolve, reject) => {
        API.delete(ENDPOINTS.NOTIFICACIONES+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.NOTIFICACIONES, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    update: (data, id) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.NOTIFICACIONES+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    })
}

export default NotificacionesService;
