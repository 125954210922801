import API from "./Config";
import ENDPOINTS from "./Enpoints";

const LogmailService = {
    getAll: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.LOGMAIL)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    })
}

export default LogmailService;
