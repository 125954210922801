import React ,{ useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import { CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell } from '@coreui/react';
import { useNavigate } from "react-router-dom";
import LogmailService from 'src/services/Logmail';
import Moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Index = () => {
  const [data, setData] = useState([])
  const [dataOr, setDataOr] = useState([])
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentDataDisplayed, setCurrentDataDisplayed] = useState({ data:[]});
  const [previousAllowed, setPreviousAllowed] = useState(false);
  const [nextAllowed, setNextAllowed] = useState(true);
  const [buscador, setBuscador] = useState('');

  useEffect(() => {
    getData();
    setCurrentPage(1);
  }, [])

  useEffect(() => {
      data &&
        setCurrentDataDisplayed(() => {
          const page = data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
          return { data: page };
        });
      setPreviousAllowed(() => currentPage > 1);
      setNextAllowed(() => currentPage < numberOfPages);
  }, [currentPage, data]);

  const getData = () =>{
    LogmailService.getAll().then(resp =>{
      let _data = resp.data;
        setData(_data);
        setDataOr(_data);
        setNumberOfPages(Math.ceil(_data.length / pageSize));
      })
      .catch(error => console.log(error));
    }
  const handlePagination = (action) => {
    if (action === "prev") {
      if (!previousAllowed) return;
      setCurrentPage((prevState) => (prevState -= 1));
    }
    if (action === "next") {
      if (!nextAllowed) return;
      setCurrentPage((prevState) => (prevState += 1));
    }
  };

  const changePageSize = (event) =>{
    if(event.target.value !== ''){
      setPageSize(parseInt(event.target.value));
      setCurrentDataDisplayed(() => {
        const page = data?.slice((currentPage - 1) * parseInt(event.target.value), currentPage * parseInt(event.target.value));
        return { data: page };
      });
      setPreviousAllowed(() => currentPage > 1);
      setNextAllowed(() => currentPage < numberOfPages);
      setNumberOfPages(Math.ceil(data.length / parseInt(event.target.value)));
    }
  }

  const saveBuscador = (event) =>{
    if(event.target.value !== ""){
        setBuscador(event.target.value);
    }else{
        let data = dataOr;
        setData(data)
        setDataC(data)
    }
  }
  const filtrarData = () =>{
    let _data = dataOr;
    _data = (_data.filter(x =>
        x.name.toUpperCase().includes(buscador.toUpperCase()) 
    ));
    setData(_data)
    setDataC(_data)
  }
  function setDataC(_data){
    setCurrentDataDisplayed(() => {
        const page = _data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
        return { data: page };
      });
    setPreviousAllowed(() => currentPage > 1);
    setNextAllowed(() => currentPage < numberOfPages);
  }
  const onCopy = () =>{
    toast.success('Copiado con éxito', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }
  function getDest(dest){
    console.log(dest)
    if(dest){
      dest = dest.replaceAll("[", "")
      dest = dest.replaceAll("]", "")
      dest = dest.replaceAll('"', "")
      //dest = dest.split(",");
    }
    return (dest);
  }
  return(
    <Row>
      <ToastContainer />
      <Col span={24} className='text-center my-4'>
        <h1>Listado de Mailings</h1>
      </Col>
      <Col span={24} className='text-end mb-4'>
          <div className='form-group'>
              <input className='form-control w-250' onChange={saveBuscador} placeholder='Buscar...' name='buscador' />
              <button className='btn btn-info btns' onClick={ filtrarData }>Buscar</button>
          </div>
      </Col>
      <Col span={24}>
        <CTable className='table responsivegene'>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>Nombre(s)</CTableHeaderCell>
                    <CTableHeaderCell>HTML</CTableHeaderCell>
                    <CTableHeaderCell>Destinatario(s)</CTableHeaderCell>
                    <CTableHeaderCell>Fecha de envio</CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {
                    currentDataDisplayed.data.length > 0 ?
                    (
                        currentDataDisplayed.data.map(
                            (item, index) =>(
                                <CTableRow key={index}>
                                    <CTableDataCell>
                                      { item.name }
                                    </CTableDataCell>
                                    <CTableDataCell>                                    
                                    <CopyToClipboard text={item.html} className="copyEle" onCopy={ onCopy }>
                                      <span>Copiar HTML<CopyOutlined className='iconCopy' /></span>
                                    </CopyToClipboard>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <span>{ getDest(item.destinations) }</span>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      { Moment(item.created_at).format("DD/MM/YYYY HH:mm A") }
                                    </CTableDataCell>
                                </CTableRow>
                            )
                        )
                    ):
                    (
                        <CTableRow>
                            <CTableHeaderCell colSpan={8}>
                                No existen mailings enviados
                            </CTableHeaderCell>
                        </CTableRow>
                    )
                }
            </CTableBody>
        </CTable>
        <div className='pag-custom d-flex justify-content-between'>
            <div>
                <div className='d-flex justify-content-center align-items-center'>
                <select className='form-control spage' value={pageSize} onChange={ changePageSize }>
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <p>
                Mostrando{" "}
                <span>{pageSize * (currentPage - 1)}</span>{" "}
                a{" "}
                <span>
                    {currentDataDisplayed && currentDataDisplayed.data.length + (currentPage - 1) * pageSize}
                </span>{" "}
                de <span>{data?.length}</span>{" "} registros
                </p>
                </div>
            </div>
            <div>
                <button onClick={() => handlePagination("prev")} disabled={ (pageSize * (currentPage - 1)) === 0}>
                    Anterior
                </button>
                <button onClick={() => handlePagination("next")} disabled={ (currentDataDisplayed.data.length + (currentPage - 1) * pageSize) === data?.length }>
                    Siguientes
                </button>
            </div>
        </div>
    </Col>
    </Row>
  )
}

export default Index;
