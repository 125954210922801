import { useState, useEffect } from "react";
import VehiculosService from "src/services/VehiculosService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const List = () =>{
    const [data, setData] = useState([]);
    const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
    const moneyFormat = (value) => new Intl.NumberFormat().format(value);

    useEffect(() => {
        getData();
    }, [])

    const getData = () =>{
        VehiculosService.getActives().then(resp =>{
            setData(resp.data)
        })
    }
    function getDescription(description){
        let desc = description.replaceAll("<br/>", "<p></p>");
        desc = desc.replaceAll("Ã¡­", "a");
        desc = desc.replaceAll("Ã³­", "o");
        desc = desc.replaceAll("Ã­", "i");
        desc = desc.replaceAll("Ã±­", "ñ");

        return desc;
    }

    const copyText = () =>{
        let _text = document.getElementById("listV");
        navigator.clipboard.writeText(`${_text.innerText}`);
        toast.success('Texto copiado con éxito', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    return(
        <div className="container">
            <ToastContainer />
            <div className="row">
                <div className="col-12 text-center">
                    <h2>Listado de vehículos</h2>                    
                </div>
                <div className="col-12 text-end">
                    <a onClick={copyText} className="ccopy">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                        </svg>
                        <span className="ms-2">Copiar contenido</span>
                    </a>
                </div>
            </div>
            <div className="row my-4">
                <div className="col-12">
                    <ol className="list-group list-group-numbered" id="listV">
                        {
                            data.length > 0 ? data.map((item, index) => (
                            <li className="list-group-item d-flex justify-content-between align-items-start mb-4">
                                <div className="ms-2 me-auto">
                                    <p className="my-0">#{ index + 1}</p>
                                    <p className="my-0">{ item.title }</p>
                                    <p className="my-0"><a href={ item.link_ml }>
                                        {
                                            item.link_ml.includes('moto') ? item.link_ml.slice(33) : item.link_ml.slice(34)
                                        }
                                    </a></p>
                                    <p className="my-0">P: { moneyFormat(item.price) }</p>
                                    <p className="my-0">{ kmFormat(item.kilometers) }</p>
                                </div>
                            </li>
                            )) : null
                        }
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default List;