import React ,{ useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import { useAuthUser } from 'react-auth-kit';
import DriverService from 'src/services/Driver';
import { useNavigate  } from "react-router-dom";
import Moment from 'moment'
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import { DeleteOutlined, CameraOutlined, CheckSquareOutlined, EyeOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component'
import { CCol, CRow } from '@coreui/react';
import { Link  } from "react-router-dom";

const Index = () => {
    const authUser = useAuthUser();
    const [showLot, setShowLot] = useState(false);
    const [data, setData] = useState([]);
    const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
    const navigate = useNavigate();
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        "search" : ''
    })

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getData(page, limit, skip);
    }, [])

    const columns = [
        {
          name: 'Fecha',
          selector: rowData => Moment(rowData.created_at).format("DD/MM/YYYY h:mm:ss a")
        },    
        {
          name: 'Placa',
          selector: row => placaBodyTemplate(row),
          sortable: true,
        },
        {
          name: 'Kilometraje',
          selector: row => kmBodyTemplate(row),
          sortable: true,
        },
        {
          name: 'Dir. Recepción',
          selector: row => row.direccion_recepcion
        },
        {
            name: 'Estado',
            selector: row => statusBodyTemplate(row)
        },      
        {
          name: 'Acciones',
          selector: rowData => {
            return <div className='d-flex'>                
            {
                rowData.estado === 'En ruta' ? (<>
                    <CameraOutlined className="iconCameraTable" onClick={() => agregarFotos(rowData.id)} /> 
                    { 
                        rowData.count_ini > 0 && rowData.count_end >  0 ? 
                        <CheckSquareOutlined  onClick={() => editar(rowData)} className='iconFinTable'/> : ''
                    }                                                         
                    </>
                    )
                : ''
            }
            <Link to={"/conductor/consultar/"+rowData.id} target='_blank'>
                <EyeOutlined className="iconViewTable"/>
            </Link>                
            {
                rowData.estado !== 'Finalizado' ?  <DeleteOutlined className='iconDeleteTable' onClick={() => eliminar(rowData.id)} /> : ''
            }
            </div>
          }
        }
    ];

    const getData = (ppage) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        DriverService.getAll(ppage,limit,skip,_filter).then(resp =>{
            let _data = resp.data;
            setTotalRows(resp.total);
            setData(_data);
            setLoading(false);
        })
    }

    const handlePageChange = page => {
        getData(page);
        setPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getData(page);    
        setLoading(false)
    };

    const saveField = (event) =>{
        let _filters = filters;
        switch (event.target.name) {
          case 'search':
            filters.search = event.target.value
            break;
          default:
            break;
        }
        setFilters(_filters)
        getData(1)
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={ rowData.estado === 'Finalizado' ? "badge rounded-pill text-bg-info" : "badge rounded-pill text-bg-danger"}>{ rowData.estado }</span> ;
    };


    const editar = (item) =>{
        setShowLot(true)
        let data = {
            estado: 'Finalizado',
            id_traslado: item.id,
            placa: item.placa,
            km: item.km,
            detalles: item.detalles,
            direccion1: item.direccion_entrega,
            direccion2: item.direccion_recepcion
        }
        DriverService.finalizar(data).then(resp =>{            
            setTimeout(() => {
                setShowLot(false)
                window.location.reload()
            }, 3000);
        })
    }

    const agregarFotos = (id) =>{
        navigate("/conductor/solicitud/fotos/"+id);
    }

    const goNew = () =>{
        navigate("/conductor/solicitud/")
    }

    const eliminar = (id) =>{
        DriverService.delete(id).then(resp =>{
            window.location.reload();
        })
    }

    const placaBodyTemplate = (rowData) => {
        return <strong>{ rowData.placa }</strong>;
    };

    const kmBodyTemplate = (rowData) => {
        return kmFormat(rowData.km);
    };


    return(
        <>
            <Row>
                <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                    <Lottie animationData={gifJson} loop={true} className="loadLottie" />
                </div>
                <Col span={24} className='text-center my-4'>
                    <h1>Listado de traslados</h1>
                </Col>
                <Col span={24} className='text-end mb-4'>
                    <a className='btn btn-primary' onClick={ goNew }>Nuevo traslado</a>
                </Col>
            </Row>
            <CRow className="justify-content-end mb-2">
                <CCol sm="12" md="4">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <input type="search" className='form-control' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
                    </span>
                </CCol>
            </CRow>
            <Row>
                <Col span={24}>
                    <DataTable
                        noDataComponent={"No se han encontrado resultados"}
                        columns={ columns }
                        data={data}
                        progressPending={loading}
                        pagination 
                        paginationServer
                        responsive             
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Index;
