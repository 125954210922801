import React, { Component, Suspense } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { RequireAuth } from "react-auth-kit";
import './scss/style.scss'
import DefaultLayout from './layout/DefaultLayout';
import { PrimeReactProvider } from 'primereact/api';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const value = {
  cssTransition: false
};
class App extends Component {
  render() {
    return (
        <PrimeReactProvider value={ value }>
          <BrowserRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route path="*" element={
                  <RequireAuth loginPath="/login">
                    <DefaultLayout />
                  </RequireAuth>}>
                </Route>
                <Route path="/login" element={<Login />}></Route>
              </Routes>
            </Suspense>
          </BrowserRouter>
        </PrimeReactProvider>
    )
  }
}

export default App
