import React ,{ useEffect, useState } from 'react';
import axios from 'axios';
import { CRow } from '@coreui/react';
import { Form, Button, Col, Input, Select, message , Upload, Image } from 'antd';
import { Slider, Checkbox  } from 'antd';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecepcionService from 'src/services/RecepcionService';
import 'react-quill/dist/quill.snow.css';
import { UploadOutlined } from '@ant-design/icons';
import $ from "jquery";
const { TextArea } = Input;
    
const estados = [
    {value: 'Bueno', label: 'Bueno'},
    {value: 'Muy Bueno', label: 'Muy Bueno'},
    {value: 'Excelente', label: 'Excelente'}
];
const duenos = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4 o mas', label: '4 o mas'},
    {value: 'No se sabe', label: 'No se sabe'},
];
const marks = {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
};
const CreateRecepcion = () => {
    const [imageFront, setImageFront] = useState('https://cars-triplo-back.s3.amazonaws.com/sinimagen.jpg');
    const [imageBack, setImageBack] = useState('https://cars-triplo-back.s3.amazonaws.com/sinimagen.jpg');
    const [showLot, setShowLot] = useState(false);
    const [form] = Form.useForm();
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [prenda, setPrenda] = useState(false);
    const [asegurado, setAsegurado] = useState(false);
    const [mantenimiento, setMantenimiento] = useState(false);
    const [recepcion, setRecepcion] = useState({
        vehiculo: "",
        placa: "",
        kilometraje: "",
        valor_soat: "",
        valor_impuesto: "",
        estado_general: "",
        nro_duenos: "",
        segunda_llave: "",
        kit_carretera: "",
        llanta_repuesto:"",
        perno_seguridad: "",
        herramientas: "",
        gato: "",
        prenda: "",
        prenda_obs: "",
        asegurado: "",
        asegurado_obs: "",
        mantenimiento: "",
        mantenimiento_obs: "",
        estado_llanta_del: "",
        estado_llanta_tra: "",
        observaciones: "",
        detalles: "",
        tarjeta_propiedad_atras: "",
        tarjeta_propiedad_frente: "",
        user_id: 0
    })
    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        if(localStorage.getItem('recepcion')){
            let _recpecion = JSON.parse(localStorage.getItem('recepcion'));
            if(_recpecion.user_id == authUser().id){
                setPrenda(_recpecion.prenda)
                setAsegurado(_recpecion.asegurado)
                setMantenimiento(_recpecion.mantenimiento)
                let _data = {
                    vehiculo: _recpecion.vehiculo,
                    placa: _recpecion.placa,
                    kilometraje: _recpecion.kilometraje,
                    valor_soat: _recpecion.valor_soat,
                    valor_impuesto: _recpecion.valor_impuesto,
                    estado_general: _recpecion.estado_general,
                    nro_duenos: _recpecion.nro_duenos,
                    segunda_llave: _recpecion.segunda_llave,
                    kit_carretera: _recpecion.kit_carretera,
                    llanta_repuesto: _recpecion.llanta_repuesto,
                    perno_seguridad: _recpecion.perno_seguridad,
                    herramientas: _recpecion.herramientas,
                    estado_llanta_del: _recpecion.estado_llanta_del,
                    estado_llanta_tra: _recpecion.estado_llanta_tra,
                    observaciones: _recpecion.observaciones,
                    detalles: _recpecion.detalles,
                    gato: _recpecion.gato,
                    prenda: _recpecion.prenda,
                    prenda_obs: _recpecion.prenda_obs,
                    asegurado: _recpecion.asegurado,
                    asegurado_obs: _recpecion.asegurado_obs,
                    mantenimiento: _recpecion.mantenimiento,
                    mantenimiento_obs: _recpecion.mantenimiento_obs,
                }
                setImageFront(_recpecion.tarjeta_propiedad_frente != '' ? _recpecion.tarjeta_propiedad_frente : 'https://cars-triplo-back.s3.amazonaws.com/sinimagen.jpg')
                setImageBack(_recpecion.tarjeta_propiedad_atras != '' ? _recpecion.tarjeta_propiedad_atras : 'https://cars-triplo-back.s3.amazonaws.com/sinimagen.jpg')
                form.setFieldsValue(_data);
            }
        }
    }, [])

    const uploadImage = async options => {
        const { onSuccess, onError, file } = options;
        var fmData = new FormData() 
        fmData.append("file", file);
        let _recepcion = localStorage.getItem('recepcion') ? JSON.parse(localStorage.getItem('recepcion')) : recepcion;
        try {
            const res = await axios.post(
              'https://back.triplo.com.co/api/v1/uploads3/recepcion_'+form.getFieldValue('placa'),
              fmData
            );
            setImageFront(res.data.uri) 
            _recepcion.tarjeta_propiedad_frente = res.data.uri
            localStorage.setItem('recepcion', JSON.stringify(_recepcion));
            message.success(`Foto frontal de tarjeta de propiedad subida con éxito`);     
            onSuccess("Ok");
            console.log("server res: ");
        } catch (err) {
            console.log("Eroor: ", err);
            message.error(`Error al subir la foto frontal de tarjeta de propiedad`);
            onError({ err });
        }
    }

    const uploadImageBack = async options => {
        const { onSuccess, onError, file } = options;
        var fmData = new FormData() 
        fmData.append("file", file);
        let _recepcion = localStorage.getItem('recepcion') ? JSON.parse(localStorage.getItem('recepcion')) : recepcion;
        try {
            const res = await axios.post(
              'https://back.triplo.com.co/api/v1/uploads3/recepcion_'+form.getFieldValue('placa'),
              fmData
            );
            setImageBack(res.data.uri)  
            _recepcion.tarjeta_propiedad_atras = res.data.uri
            localStorage.setItem('recepcion', JSON.stringify(_recepcion));
            message.success(`Foto posterior de tarjeta de propiedad subida con éxito`);    
            onSuccess("Ok");
            console.log("server res: ");
        } catch (err) {
            console.log("Eroor: ", err);
            message.error(`Error al subir la foto posterior de tarjeta de propiedad`);
            onError({ err });
        }
    }

    const onFinish = (values) =>{
        $('body,html').stop(true,true).animate({
            scrollTop: $('body').offset().top
        },100);
        let data = {
            placa: values.placa,
            vehiculo: values.vehiculo,
            kilometraje: values.kilometraje,
            valor_soat: values.valor_soat ? values.valor_soat : '',
            valor_impuesto: values.valor_impuesto ? values.valor_soat : '',
            estado_general: values.estado_general,
            segunda_llave: values.segunda_llave ? values.segunda_llave : '',
            kit_carretera: values.kit_carretera ? values.kit_carretera : false,
            llanta_repuesto: values.llanta_repuesto ? values.llanta_repuesto : false,
            perno_seguridad: values.perno_seguridad ? values.perno_seguridad : false,
            user_id: authUser().id,
            herramientas: values.herramientas ? values.herramientas : false,
            gato: values.gato ? values.gato : false,
            prenda: values.prenda ? values.prenda : false,
            prenda_obs: values.prenda ? values.prenda_obs : '',
            asegurado: values.asegurado ? values.asegurado : false,
            asegurado_obs: values.asegurado ? values.asegurado_obs : '',
            mantenimiento: values.mantenimiento ? values.mantenimiento : false,
            mantenimiento_obs: values.mantenimiento ? values.mantenimiento_obs : '',
            nro_duenos: values.nro_duenos,
            observaciones: values.observaciones ? values.observaciones : '', 
            detalles: values.detalles ? values.detalles : '', 
            tarjeta_propiedad_frente: imageFront, 
            tarjeta_propiedad_atras: imageBack,
            estado_llanta_del: values.estado_llanta_del ? values.estado_llanta_del : 50,
            estado_llanta_tra: values.estado_llanta_tra ? values.estado_llanta_tra : 50,
            pdf: "https://intranet.triplo.com.co/pdfs/Tarjeta_de_propiedad_"+values.placa+".pdf"
        };
        let _data = {
            image1: imageFront,
            image2: imageBack
        }
        setShowLot(true)
        setTimeout(() => {
            RecepcionService.save(data).then(resp => {
                RecepcionService.createPDF(values.placa, _data).then(rr => {
                    toast.success('Recepción creada con éxito', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });            
                    setShowLot(false)    
                    setTimeout(() => {      
                        localStorage.removeItem('recepcion')                    
                        navigate('/recepcion');
                    }, 2000);
                })
            })
        }, 4000);        
    }
    
    const saveItem = (event) =>{
        let _recepcion = localStorage.getItem('recepcion') ? JSON.parse(localStorage.getItem('recepcion')) : recepcion;
        _recepcion.user_id = authUser().id;
        switch (event.target.id) {
            case 'basic_kit_carretera':
                _recepcion.kit_carretera = event.target.checked
                break;
            case 'basic_segunda_llave':
                _recepcion.segunda_llave = event.target.value
                break;
            case 'basic_llanta_repuesto':
                _recepcion.llanta_repuesto = event.target.checked
                break;
            case 'basic_perno_seguridad':
                _recepcion.perno_seguridad = event.target.checked
                break;
            case 'basic_herramientas':
                _recepcion.herramientas = event.target.checked
                break;
            case 'basic_gato':
                _recepcion.gato = event.target.checked
                break;
            case 'basic_prenda':
                _recepcion.prenda = event.target.checked
                break;
            case 'basic_prenda_obs':
                _recepcion.prenda_obs = event.target.value
                break;
            case 'basic_asegurado':
                _recepcion.asegurado = event.target.checked
                break;
            case 'basic_asegurado_obs':
                _recepcion.asegurado_obs = event.target.value
                break;
            case 'basic_mantenimiento':
                _recepcion.mantenimiento = event.target.checked
                break;
            case 'basic_mantenimiento_obs':
                _recepcion.mantenimiento_obs = event.target.value
                break;
            case 'basic_vehiculo':
                _recepcion.vehiculo = event.target.value
                break;
            case 'basic_placa':
                _recepcion.placa = event.target.value
                break;
            case 'basic_kilometraje':
                _recepcion.kilometraje = event.target.value
                break;
            case 'basic_valor_soat':
                _recepcion.valor_soat = event.target.value
                break;
            case 'basic_valor_impuesto':
                _recepcion.valor_impuesto = event.target.value
                break;
            case 'basic_observaciones':
                _recepcion.observaciones = event.target.value
                break;
            case 'basic_detalles':
                _recepcion.detalles = event.target.value
                break;
            default:
                break;
        }
        console.log(prenda)
        localStorage.setItem('recepcion', JSON.stringify(_recepcion));
    }
    const saveEstado = (event) =>{
        let _recepcion = localStorage.getItem('recepcion') ? JSON.parse(localStorage.getItem('recepcion')) : recepcion;
        _recepcion.estado_general = event;
        localStorage.setItem('recepcion', JSON.stringify(_recepcion));
    }
    const saveDuenos = (event) =>{
        let _recepcion = localStorage.getItem('recepcion') ? JSON.parse(localStorage.getItem('recepcion')) : recepcion;
        _recepcion.nro_duenos = event;
        localStorage.setItem('recepcion', JSON.stringify(_recepcion));
    }
    const saveDelanteras = (event) =>{
        let _recepcion = localStorage.getItem('recepcion') ? JSON.parse(localStorage.getItem('recepcion')) : recepcion;
        _recepcion.estado_llanta_del = event;
        localStorage.setItem('recepcion', JSON.stringify(_recepcion));
    }
    const saveTraseras = (event) =>{
        let _recepcion = localStorage.getItem('recepcion') ? JSON.parse(localStorage.getItem('recepcion')) : recepcion;
        _recepcion.estado_llanta_tra = event;
        localStorage.setItem('recepcion', JSON.stringify(_recepcion));
    }
    return(
        <>
            <ToastContainer />
            <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" />
            </div>
            <CRow>
                <Col md={24} xs={24} className='text-center mb-4'>                    
                    <h3>Para Publicar - Crear</h3>                    
                </Col>
            </CRow>
            <Form name="basic" form={form} onFinish={onFinish} layout='vertical' onChange={saveItem}>
                <CRow>
                    <Col md={24} xs={24}>
                        <Form.Item label="Vehículo" name="vehiculo"
                            rules={[
                                {
                                    required: true,
                                    message: 'El vehículo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Vehículo' />
                        </Form.Item>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="Placa"  name="placa"
                            rules={[
                                {
                                    required: true,
                                    message: 'La placa es requerida!',
                                }
                            ]}>
                            <Input type='text' onInput={e => e.target.value = e.target.value.toUpperCase()} placeholder='Placa del vehículo' />
                        </Form.Item>                        
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="Kilometraje"  name="kilometraje"
                            rules={[
                                {
                                    required: true,
                                    message: 'El kilometraje es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Kilometraje del vehículo' />
                        </Form.Item>                        
                    </Col>
                </CRow>
                <CRow>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="Valor SOAT"  name="valor_soat">
                            <Input type='text' placeholder='Valor SOAT' />
                        </Form.Item>                        
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="Valor impuesto"  name="valor_impuesto">
                            <Input type='text' placeholder='Valor Impuesto' />
                        </Form.Item>                        
                    </Col>
                </CRow>                
                <CRow>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="Estado del vehículo" name="estado_general"
                            rules={[
                                {
                                    required: true,
                                    message: 'El estado es requerido!',
                                },
                            ]}>
                            <Select options={ estados } onChange={saveEstado}></Select>
                        </Form.Item>                       
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="Nro de dueños" name="nro_duenos"
                            rules={[
                                {
                                    required: true,
                                    message: 'El nro de dueños es requerido!',
                                },
                            ]}>
                            <Select options={ duenos } onChange={saveDuenos}></Select>
                        </Form.Item>                        
                    </Col>
                </CRow>
                <CRow>
                    <Col md={24} xs={24}><hr/> </Col>
                </CRow>
                <CRow>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="¿Cuántas llaves tiene?"  name="segunda_llave"
                            rules={[
                                {
                                    required: true,
                                    message: 'La segunda llave es requerida!',
                                }
                            ]}>
                            <Input type='text'placeholder='Segunda llave' />
                        </Form.Item>                      
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="" name="kit_carretera" valuePropName="checked">
                            <Checkbox>¿Tiene kit de carretera?</Checkbox>
                        </Form.Item>                         
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="" name="llanta_repuesto" valuePropName="checked">
                            <Checkbox>¿Tiene llanta de repuesto?</Checkbox>
                        </Form.Item>                       
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="" name="perno_seguridad" valuePropName="checked">
                            <Checkbox>¿Tiene perno de seguridad?</Checkbox>
                        </Form.Item>                         
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="" name="herramientas" valuePropName="checked">
                            <Checkbox>¿Tiene herramientas?</Checkbox>
                        </Form.Item>                         
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="" name="gato" valuePropName="checked">
                            <Checkbox>¿Tiene gato?</Checkbox>
                        </Form.Item>                         
                    </Col>
                    <Col md={12} xs={24}>                        
                        <Form.Item label="" name="prenda" valuePropName="checked" onChange={ () => setPrenda(!prenda)}>
                            <Checkbox>¿Tiene prenda?</Checkbox>
                        </Form.Item>   
                        {
                            prenda ? 
                            <Form.Item label="Observación de prenda" name="prenda_obs">
                                <TextArea rows={2} />
                            </Form.Item> : ''
                        }  
                                        
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="" name="asegurado" valuePropName="checked" onChange={ () => setAsegurado(!asegurado)}>
                            <Checkbox>¿Está asegurado?</Checkbox>
                        </Form.Item>   
                        {
                            asegurado ? 
                            <Form.Item label="Observación de asegurado" name="asegurado_obs">
                                <TextArea rows={2} />
                            </Form.Item> : ''
                        }                      
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="" name="mantenimiento" valuePropName="checked" onChange={ () => setMantenimiento(!mantenimiento)}>
                            <Checkbox>¿Mantenimiento?</Checkbox>
                        </Form.Item> 
                        {
                            mantenimiento ? 
                            <Form.Item label="Observación de mantenimiento" name="mantenimiento_obs">
                                <TextArea rows={2} />
                            </Form.Item> : ''
                        }                        
                    </Col>
                </CRow>
                <CRow>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="Estado ruedas delanteras(%)" name="estado_llanta_del">
                            <Slider marks={marks} defaultValue={50} min={0} max={100} step={10} tooltip={{ open: true }} onChange={saveDelanteras}/>
                        </Form.Item>                         
                    </Col>
                    <Col md={12} xs={24} >                        
                        <Form.Item label="Estado ruedas traseras(%)" name="estado_llanta_tra">
                            <Slider marks={marks} defaultValue={50} min={0} max={100} step={10} tooltip={{ open: true }} onChange={saveTraseras}/>
                        </Form.Item>                         
                    </Col>
                </CRow>
                <CRow>
                    <Col md={24} xs={24}><hr/> </Col> 
                </CRow>
                <CRow>
                    <Col md={24} xs={24}>
                        <Form.Item label="Detalles" name="detalles">
                            <TextArea rows={4} /> 
                        </Form.Item>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col md={24} xs={24}>
                        <Form.Item label="Observaciones" name="observaciones">
                            <TextArea rows={4} /> 
                        </Form.Item>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col xs={12} span={24}>
                        <Image width={200} src={imageFront} placeholder="Ver"/>
                        <Form.Item label="Foto frontal de tarjeta de propiedad"  name="tarjeta_propiedad_frente">
                            <Upload customRequest={uploadImage}  accept="image/*">
                                <Button icon={<UploadOutlined />}>Click para cargar</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col xs={12} span={24}>
                        <Image width={200} src={imageBack} placeholder="Ver"/>
                        <Form.Item label="Foto posterior de tarjeta de propiedad"  name="tarjeta_propiedad_atras">
                            <Upload customRequest={uploadImageBack}  accept="image/*">
                                    <Button icon={<UploadOutlined />}>Click para cargar</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow className='mt-4 mb-4'>
                    <Col span={24}  md={24} xs={24} className='text-center'>
                        <Form.Item>
                            <Button type='primary'  htmlType="submit">Guardar</Button>
                        </Form.Item>
                    </Col>
                </CRow>
            </Form>
        </>
    )
}

export default CreateRecepcion;