import API from "./Config";
import ENDPOINTS from "./Enpoints";

const RecepcionService = {
    getAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("recepcion?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    get: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.RECEPCION+"/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    delete: (id) => new Promise((resolve, reject) => {
        API.delete(ENDPOINTS.RECEPCION+"/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.RECEPCION, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    update: (data, id) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.RECEPCION+"/"+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getOne: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.RECEPCION+"/"+id)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    createPDF: (placa,data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.RECEPCION+"/pdf/"+placa, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
}

export default RecepcionService;
