import React ,{ useEffect, useState } from 'react'
import { CCol, CRow, CFormSelect, CFormLabel, CButton, CBadge, CFormInput } from '@coreui/react'
import MakeService from 'src/services/MakeService';
import QuoteService from 'src/services/QuoteService';
import ModelService from 'src/services/ModelService';
import YearService from 'src/services/YearService';
import VersionService from 'src/services/VersionService';
import BitacoraService from 'src/services/Bitacora';
import { useAuthUser } from 'react-auth-kit';

const Prices = () => {
    const [makes, setMakes] = useState([]);
    const [make, setMake] = useState(0);
    const [years, setYears] = useState([]);
    const [year, setYear] = useState(0);
    const [models, setModels] = useState([]);
    const [model, setModel] = useState(0);
    const [versions, setVersions] = useState([]);
    const [version, setVersion] = useState(0);
    const [precioOfert, setPrecioOfert] = useState(0);
    const moneyFormat = (value) => new Intl.NumberFormat().format(value)+" COP";
    const [prom, setProm] = useState(0);
    const [darPrecio, setDarPrecio] = useState(false);
    const [urlTucarro, setUrlTCarro] = useState("");
    const [status, setStatus] = useState("");
    const [category, setCategory] = useState("");
    const [keys, setKeys] = useState("");
    const [km, setKm] = useState(0);
    const [errorKm, setErrorKm] = useState("");
    const authUser = useAuthUser();

    useEffect(() => {
        getMakes();
    }, [])

    const getMakes = () =>{
        MakeService.getAll()
        .then(data => {
            setMakes(data.data);
        })
        .catch( error => console.log(error));
    }
    const getYears = (event) =>{
        if(event.target.value !== ""){
            setMake(event.target.value);
            YearService.getYearByMake(event.target.value)
            .then(data => {
                setYears(data.data);
            })
            .catch( error => console.log(error));
        }
    }
    const getModels = (event) =>{
        if(event.target.value !== ""){
            setYear(event.target.value);
            ModelService.getModelsByYear(event.target.value)
            .then(data => {
                setModels(data.data);
            })
            .catch( error => console.log(error));
        }
    }
    const getVersions = (event) =>{
        if(event.target.value !== ""){
            setModel(event.target.value);
            VersionService.getVersionsByModel(event.target.value)
            .then(data => {
                setVersions(data.data);
            })
            .catch( error => console.log(error));
        }
    }
    const saveVersion = (event) =>{
        if(event.target.value !== ""){
            setVersion(event.target.value);
        }
    }
    const saveStatus = (event) =>{
        if(event.target.value !== ""){
            setStatus(event.target.value);
        }
    }
    const saveKm = (event) =>{
        if(event.target.value !== ""){
            setKm(event.target.value);
            setErrorKm("");
        }
    }
    const getPrice = (event) =>{
        event.preventDefault();
        setPrecioOfert(0);
        setProm(0);

        let _make = makes.find(x => x.id == make).name.toLowerCase();
        let _model = models.find(x => x.id == model);
        let _year = years.find(x => x.id == year).name;
        let _version = versions.find(x => x.id == version);
   
        (_version.category === 4 || (parseInt(km) > 110000 && _make !== 'TOYOTA')) ? setDarPrecio(false) : setDarPrecio(true);
        
        _make = _make.replaceAll(' ', '-');
        let _url = "https://carros.tucarro.com.co/"+_make.toLowerCase()+"/"+_model.sname.toLowerCase()+"/"+_year+"_OrderId_PRICE_ITEM*CONDITION_2230581_NoIndex_True";
        setUrlTCarro(_url);
        let _data = {
            "url": _url,
            "category": _version.category,
            "status": status,
            "keys": _version.key,
            "km": parseInt(km),
            "year": _year,
            "make": _make
        }
        setCategory(""+_version.category);
        setKeys("["+_version.key+"]")
        QuoteService.getTuCarro(_data)
        .then(resp_tc => {
            setProm(resp_tc.respuesta.prom_original);
            setPrecioOfert(resp_tc.respuesta.offer_price)
            _data = {
                make: _make,
                year: _year,
                model: _model.name,
                version: _version.name,
                category: _version.category,
                url: _url,
                km: km,
                prom_tucarro: resp_tc.respuesta.prom_original,
                price_ofert: resp_tc.respuesta.offer_price,
                status: status,
                email: authUser().email
            }
            //Guardamos en bitacora
            BitacoraService.save(_data).then(resp =>{
                console.log(resp);
            })
            .catch(error => console.log(error));
        })
        .catch(error => console.log(error))
    }

    return (
    <>
      <CRow>
        <CCol className='text-center mb-4' sm={12}>
          <h1>Consulta de precios</h1>
        </CCol>
        <CCol sm={6}>
            <CRow>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Selecione la marca</CFormLabel>
                        <CFormSelect aria-label="Seleccione la marca" onChange={getYears}>
                            <option value="">Seleccione</option>
                            {
                                makes.length > 0 ? makes.map(
                                (item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                                ): ''
                            }
                        </CFormSelect>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Selecione el año</CFormLabel>
                        <CFormSelect aria-label="Seleccione el año" onChange={getModels}>
                            <option value="">Seleccione</option>
                            {
                                years.length > 0 ? years.map(
                                (item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                                ): ''
                            }
                        </CFormSelect>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Selecione el modelo</CFormLabel>
                        <CFormSelect aria-label="Seleccione el modelo" onChange={getVersions}>
                            <option value="">Seleccione</option>
                            {
                                models.length > 0 ? models.map(
                                (item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                                ): ''
                            }
                        </CFormSelect>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Selecione la versión</CFormLabel>
                        <CFormSelect aria-label="Seleccione la versión" onChange={saveVersion}>
                            <option value="">Seleccione</option>
                            {
                                versions.length > 0 ? versions.map(
                                (item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                                ): ''
                            }
                        </CFormSelect>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Kilometraje</CFormLabel>
                        <CFormInput type='number' onChange={saveKm}></CFormInput>
                        <p className='small'>{ errorKm}</p>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Selecione el estado del vehículo</CFormLabel>
                        <CFormSelect aria-label="Seleccione el estado del vehículo" onChange={saveStatus}>
                            <option value="">Seleccione</option>
                            <option value="Excelente">Excelente</option>
                            <option value="Muy Bueno">Muy Bueno</option>
                            <option value="Bueno">Bueno</option>
                            <option value="Semi Bueno">Semi-Bueno</option>
                            <option value="Regular">Regular</option>
                        </CFormSelect>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CButton className='btn btn-primary' onClick={getPrice}>Calcular precio</CButton>
                    </div>
                </CCol>
            </CRow>
        </CCol>
        <CCol sm={6}>
            <CRow>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>URL</CFormLabel>
                        <p className='mb-0 pprecios'><a href={urlTucarro} target={'_blank'} rel="noreferrer">{ urlTucarro }</a></p>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Categoria</CFormLabel>
                        <p className='mb-0 pprecios'>{ category }</p>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Keys</CFormLabel>
                        <p className='mb-0 pprecios'>{ keys }</p>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='form-group'>
                        <CFormLabel>Promedio TuCarro</CFormLabel>
                        <p className='mb-0 pprecios'>{ moneyFormat(prom) }</p>
                    </div>
                </CCol>
                <CCol sm={12}>
                    <div className='text-center'>
                        <h3>Precio ofrecido</h3>
                        {
                            darPrecio ?
                            <CBadge color="success">Dar precio</CBadge> :
                            <CBadge color="danger">Dar precio</CBadge>
                        }  
                        <h4>{ moneyFormat(precioOfert) }</h4>
                    </div>
                </CCol>
            </CRow>
        </CCol>
      </CRow>
    </>
  )
}

export default Prices
