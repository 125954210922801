import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import { cilLockLocked } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useSignOut, useAuthUser } from "react-auth-kit";

const AppHeaderDropdown = () => {
  const navigate = useNavigate()
  const singOut = useSignOut();
  const authUser = useAuthUser();

  useEffect(() => {
    if(authUser().names === ''){
      logout(); 
    }
  }, [])

  const logout = () => {
    singOut();
    navigate("/login");
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
      <span><b>{ authUser().names }</b></span>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem onClick={logout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Salir
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
