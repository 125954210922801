import React ,{ useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { CButton, CCol, CRow } from '@coreui/react';
import DataTable from 'react-data-table-component'
import RecepcionService from 'src/services/RecepcionService';
import { useAuthUser } from 'react-auth-kit';
import Moment from 'moment'
import { FormOutlined, EyeOutlined} from '@ant-design/icons';
import { Link  } from "react-router-dom";

const Recepcion = () => {
    const navigate = useNavigate();
    const authUser = useAuthUser();
    const [data, setData] = useState([]);
    const KMFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        "search" : ''
    })
    
    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getData(page, limit, skip);
    }, []);

    const columns = [
        {
          name: 'Fecha',
          selector: rowData => Moment(rowData.created_at).format("DD/MM/YYYY h:mm:ss a")
        }, 
        {
            name: 'Vehículo',
            selector: row => row.vehiculo,
            sortable: true,
        },   
        {
          name: 'Placa',
          selector: row => <strong>{row.placa}</strong>,
          sortable: true,
        },
        {
          name: 'Kilometraje',
          selector: row => KMFormat(row.kilometraje),
          sortable: true,
        },
        {
          name: 'Estado',
          selector: row => statusBodyTemplate(row)
        },       
        {
          name: 'Acciones',
          selector: rowData => {
            return <div className='d-flex justify-content-between'>
                <div className='text-center cc1'>
                    <Link to={"/recepcion/editar/"+rowData.id}>
                    <FormOutlined className='iconCameraTable2' title='Editar'/>
                    </Link>
                </div>
                <div className='text-center cc1'>
                    <Link to={"/recepcion/ver/"+rowData.id}>
                    <EyeOutlined className='iconAprobar' title='Ver'/>
                    </Link>
                </div>
            </div>           
          }
        }
    ];

    const getData = (ppage) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        RecepcionService.getAll(ppage,limit,skip,_filter).then(resp =>{
            let _data = resp.data;
            setTotalRows(resp.total);
            setData(_data);
            setLoading(false);
        })
    }

    const handlePageChange = page => {
        getData(page);
        setPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getData(page);    
        setLoading(false)
    };

    const saveField = (event) =>{
        let _filters = filters;
        switch (event.target.name) {
          case 'search':
            filters.search = event.target.value
            break;
          default:
            break;
        }
        setFilters(_filters)
        getData(1)
    }

    const statusBodyTemplate = (rowData) => {
        let badge = '';
        switch (rowData.estado_general) {
            case 'Bueno':
                badge = 'info';
                break;
            case 'Muy Bueno':
                badge = 'warning';
                break;
            case 'Excelente':
                badge = 'success';
                break;
            default:
                break;
        }
        return <span className={'badge rounded-pill text-bg-'+badge}>{rowData.estado_general.toUpperCase()}</span>;
    };

    const crearRecepcion = () =>{
        navigate("/recepcion/crear/")
    }

    return(
        <>
            <CRow>
                <CCol xs={12} className='text-center mb-4'>
                    <h3>Recepciones</h3>
                </CCol>
            </CRow>
            <CRow>
                <CCol xs={12}>
                    <div className='fButton'>
                        <CButton onClick={ crearRecepcion }>Nueva recepcion</CButton>
                    </div>
                </CCol>
            </CRow>
            <div className="row justify-content-end mb-2">
                <div className='col-2'>
                <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <input type="search" className='form-control' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
                    </span>
                </div>
            </div>
            <CRow>
                <CCol className='ma-2'>
                <DataTable
                    noDataComponent={"No se han encontrado resultados"}
                    columns={ columns }
                    data={data}
                    progressPending={loading}
                    pagination 
                    paginationServer
                    responsive             
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
                </CCol>
            </CRow>
        </>
    )
}

export default Recepcion;