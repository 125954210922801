import React,{ useEffect, useState }  from 'react'
import ReporteService from 'src/services/Reportes'
import DashboardService from 'src/services/DashboardService';
import './Dashboard.scss'
import { CCol, CRow } from '@coreui/react';
import { Link } from 'react-router-dom'
import Moment from 'moment'

const Dashboard = () => {
  const [reporte, setReporte] = useState({})
  const [campanas, setCampanas] = useState([])
  const [campana, setCampana] = useState({})
  const [unsubscribed, setUnsubscribed] = useState({})
  const [selCampana, setSelCampana] = useState(false)
  useEffect(() => {
    getCampanas();
    ReporteService.getAll().then(resp =>{
      let _reporte = {
        usuarios_baja_count: 0,
        usuarios_alta_count: 0,
        ret_pendientes_count: 0,
        publicados_count: 0,
        sinpublicar_count: 0,
        vendidos_count: 0
      };
      _reporte.usuarios_baja_count = resp.usuarios_baja_count;
      _reporte.usuarios_alta_count = resp.usuarios_alta_count;
      _reporte.ret_pendientes_count = resp.ret_pendientes_count;
      _reporte.publicados_count = resp.publicados_count;
      _reporte.sinpublicar_count = resp.sinpublicar_count;
      _reporte.vendidos_count = resp.vendidos_count;
      setTimeout(() => {
        setReporte(_reporte)
      }, 1000);
      
    })
  }, []);

  const getCampanas = () =>{
    DashboardService.getCampanas().then(resp => {
      let _resp = resp.data
      let _orden = Array.from(_resp, (item) => item).sort((a, b) => b.web_id - a.web_id);
      setCampanas(_orden)      
    })
  }

 const selectCamapana = (event) =>{
    let id = event.target.value;
    DashboardService.getCampana(id).then(resp =>{
      console.log(resp.campaign)
      setTimeout(() => {
        setCampana(resp.campaign);
        setSelCampana(true)
      }, 1500);
    })
    DashboardService.getUnsubscribed(id).then(resp =>{
      setTimeout(() => {
        setUnsubscribed(resp.response);
        setSelCampana(true)
      }, 1500);
    })
  }
  return <>
    <div className="container">
      <CRow className="mb-4">
        <CCol sm="12" md="6" lg="4">
          <div className="dashboard-report-card blue">
            <div className="card-content">
              <Link to={ '/vehiculos' } className='linkTo'>
                <span className="card-title">Carros vendidos<br/> este mes</span>     
              </Link>         
            </div>
            <div className="card-media">
              <span className="card-count">{ reporte.vendidos_count }</span>
            </div>
          </div>
        </CCol>
        <CCol sm="12" md="6" lg="4">
          <div className="dashboard-report-card red">
            <div className="card-content">
              <Link to={ '/vehiculos' } className='linkTo'>
                <span className="card-title">Carros activos<br/> sin publicar</span>             
              </Link> 
            </div>
            <div className="card-media">
              <span className="card-count">{ reporte.sinpublicar_count }</span>
            </div>
          </div>
        </CCol>
        <CCol sm="12" md="6" lg="4">
          <div className="dashboard-report-card green">
            <div className="card-content">
              <Link to={ '/vehiculos' } className='linkTo'>
                <span className="card-title">Carros activos<br/> publicados</span>    
              </Link>          
            </div>
            <div className="card-media">
              <span className="card-count">{ reporte.publicados_count }</span>
            </div>
          </div>
        </CCol>
        <CCol sm="12" md="6" lg="4">
          <div className="dashboard-report-card yellow">
            <div className="card-content">
              <Link to={ '/retomas' } className='linkTo'>
                <span className="card-title">Retomas pendientes<br/> por aprobar</span> 
              </Link>             
            </div>
            <div className="card-media">
              <span className="card-count">{ reporte.ret_pendientes_count }</span>
            </div>
          </div>
        </CCol>
        <CCol sm="12" md="6" lg="4">
          <div className="dashboard-report-card orange">
            <div className="card-content">
              <Link to={ '/clientes' } className='linkTo'>
                <span className="card-title">Usuarios registrados<br/> este mes</span>       
              </Link>       
            </div>
            <div className="card-media">
              <span className="card-count">{ reporte.usuarios_alta_count }</span>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="12"><hr/></CCol>
        <CCol lg="6" sm="12" md="6" >
          <h2>Estadisticas de Mailchimp</h2>
          <span>Por favor seleccione una campaña</span>
        </CCol>
        <CCol lg="6" sm="12" md="6" >
          <select className='form-select' onChange={selectCamapana}>
            <option>Seleccione una opcion</option>
            {
                campanas.length > 0 ? campanas.map((item) => (
                  <option value={item.id} key={'make_'+item.id}>{ item.settings.subject_line } - { Moment(item.send_time).format("DD/MM/YYYY h:mm:ss a") }</option>
                )): ''
              }
          </select>
        </CCol>
      </CRow>
      {
        selCampana ? 
        <CRow className='mt-2 mb-4'>
        <CCol lg="3" sm="12" md="3" >
          <div className="dashboard-report-card black">
            <div className="card-content">
              <span className="card-title">Total envios</span>             
            </div>
            <div className="card-media2">
              <span className="card-count">{ campana.emails_sent }</span>
            </div>
          </div>
        </CCol>
        <CCol lg="3" sm="12" md="3" >
          <div className="dashboard-report-card black">
            <div className="card-content">
              <span className="card-title">Total abiertos</span>             
            </div>
            <div className="card-media2">
              <span className="card-count">{ campana.report_summary.unique_opens ? campana.report_summary.unique_opens : 0 }</span>
            </div>
          </div>
        </CCol>
        <CCol lg="3" sm="12" md="3" >
          <div className="dashboard-report-card black">
            <div className="card-content">
              <span className="card-title">Total clics</span>             
            </div>
            <div className="card-media2">
              <span className="card-count">{ campana.report_summary.clicks }</span>
            </div>
          </div>
        </CCol>
        <CCol lg="3" sm="12" md="3" >
          <div className="dashboard-report-card black">
            <div className="card-content">
              <span className="card-title">Usuarios de baja</span>             
            </div>
            <div className="card-media2">
              <span className="card-count">{ unsubscribed.total_items }</span>
            </div>
          </div>
        </CCol>
      </CRow> : null
      }
      
    </div>
  </>
}

export default Dashboard
