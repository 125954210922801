import API from "./Config";
import ENDPOINTS from "./Enpoints";

const PruebaRutasService = {
    getAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("pruebas-ruta?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.PRUEBA_RUTAS, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getByPlate: (plate) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.CARS+"search-by-plate/"+plate)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    generatePDF: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.ACTAS+"/generate-pdf")
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
}

export default PruebaRutasService;
