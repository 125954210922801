import React ,{ useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import { CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell } from '@coreui/react';
import LogmailService from 'src/services/Logmail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Index = () => {
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentDataDisplayed, setCurrentDataDisplayed] = useState({ data:[]});
  const [previousAllowed, setPreviousAllowed] = useState(false);
  const [nextAllowed, setNextAllowed] = useState(true);

  useEffect(() => {
    getData();
    setCurrentPage(1);
  }, [])

  useEffect(() => {
      data &&
        setCurrentDataDisplayed(() => {
          const page = data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
          return { data: page };
        });
      setPreviousAllowed(() => currentPage > 1);
      setNextAllowed(() => currentPage < numberOfPages);
  }, [currentPage, data]);

  const getData = () =>{
    let _data = [
        {'name': "Cron Mercado Libre", "description":"Cron que revisa y sincroniza la informacion desde Mercado Libre hacia la intranet", "intervalo": "Diario a las 7 p.m"},
        {'name': "Cron Traslados", "description":"Cron que revisa si quedó pendiente algún traslado. Luego de 3 horas y envia mensaje al creador del traslado.", "intervalo": "Diario"},
        {'name': "Cron Mailing", "description":"Cron para enviar el mailing a los clientes que aún no se dan de baja", "intervalo": "Cada 15 días (viernes)"},
    ];
    setData(_data);
    // LogmailService.getAll().then(resp =>{
    //   let _data = resp.data;
    //     setData(_data);
    //     setNumberOfPages(Math.ceil(_data.length / pageSize));
    //   })
    //   .catch(error => console.log(error));
    }
  const handlePagination = (action) => {
    if (action === "prev") {
      if (!previousAllowed) return;
      setCurrentPage((prevState) => (prevState -= 1));
    }
    if (action === "next") {
      if (!nextAllowed) return;
      setCurrentPage((prevState) => (prevState += 1));
    }
  };

  const changePageSize = (event) =>{
    if(event.target.value !== ''){
      setPageSize(parseInt(event.target.value));
      setCurrentDataDisplayed(() => {
        const page = data?.slice((currentPage - 1) * parseInt(event.target.value), currentPage * parseInt(event.target.value));
        return { data: page };
      });
      setPreviousAllowed(() => currentPage > 1);
      setNextAllowed(() => currentPage < numberOfPages);
      setNumberOfPages(Math.ceil(data.length / parseInt(event.target.value)));
    }
  }


  return(
    <Row>
      <ToastContainer />
      <Col span={24} className='text-center my-4'>
        <h1>Listado de Crons</h1>
      </Col>
      <Col span={24}>
        <CTable className='table responsivegene'>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>Nombre(s)</CTableHeaderCell>
                    <CTableHeaderCell>Descripción</CTableHeaderCell>
                    <CTableHeaderCell>Intervalo</CTableHeaderCell>
                    <CTableHeaderCell>Acciones</CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {
                    currentDataDisplayed.data.length > 0 ?
                    (
                        currentDataDisplayed.data.map(
                            (item, index) =>(
                                <CTableRow key={index}>
                                    <CTableDataCell>
                                      { item.name }
                                    </CTableDataCell>
                                    <CTableDataCell>                                    
                                        { item.description }
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <span>{ item.intervalo }</span>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <button className='btn btn-info'>Administrar</button>
                                    </CTableDataCell>
                                </CTableRow>
                            )
                        )
                    ):
                    (
                        <CTableRow>
                            <CTableHeaderCell colSpan={8}>
                                No existen mailings enviados
                            </CTableHeaderCell>
                        </CTableRow>
                    )
                }
            </CTableBody>
        </CTable>
        <div className='pag-custom d-flex justify-content-between'>
            <div>
                <div className='d-flex justify-content-center align-items-center'>
                <select className='form-control spage' value={pageSize} onChange={ changePageSize }>
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <p>
                Mostrando{" "}
                <span>{pageSize * (currentPage - 1)}</span>{" "}
                a{" "}
                <span>
                    {currentDataDisplayed && currentDataDisplayed.data.length + (currentPage - 1) * pageSize}
                </span>{" "}
                de <span>{data?.length}</span>{" "} registros
                </p>
                </div>
            </div>
            <div>
                <button onClick={() => handlePagination("prev")} disabled={ (pageSize * (currentPage - 1)) === 0}>
                    Anterior
                </button>
                <button onClick={() => handlePagination("next")} disabled={ (currentDataDisplayed.data.length + (currentPage - 1) * pageSize) === data?.length }>
                    Siguientes
                </button>
            </div>
        </div>
    </Col>
    </Row>
  )
}

export default Index;
