import React ,{ useState } from 'react'
import { Form, Button, Col, Input, Row, Select } from 'antd';
import { CContainer, CRow } from '@coreui/react';
import NotificacionesService from 'src/services/Notificaciones';
import $ from "jquery";
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import TextArea from 'antd/es/input/TextArea';

const New = () => {
  const [showLot, setShowLot] = useState(false);
  //const [showPhone, setShowPhone] = useState(false);
  //const [showEmail, setShowEmail] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const navigate = useNavigate();

	const onFinish = async (values) =>  {
    $('body,html').stop(true,true).animate({
      scrollTop: $('body').offset().top
    },100);
    setShowLot(true)
    setDisabledButton(true)
    let data = {
        name: values.name,
        description: values.description ? values.description : '',
        date_ini: values.date_ini,
        date_end: values.date_end ? values.date_end : '',
        type: "email",
        email: values.email,
        phone: "",
        frequence: values.frequence,
        created_by: 1,
        subject: values.subject,
        message: values.message
    };
    console.log(data)
    await NotificacionesService.save(data).then(x =>{
      toast.success('Notificación creada con éxito', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
          setShowLot(false)
          setDisabledButton(false)
          navigate('/notificaciones')
      }, 3000);
    })
	}
	const onFinishFailed = async (values) =>  {
    console.log('Failed:', values);
	}
  const changeType = (value) =>{
    /*if(value === 'phone'){
      setShowEmail(false)
      setShowPhone(true)
    }else{
      setShowEmail(true)
      setShowPhone(false)
    }*/
  }
  return(
  	<CContainer>
      <ToastContainer />
      <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
          <Lottie animationData={gifJson} loop={true} className="loadLottie" />
      </div>
    <Row>
        <Col span={24} className='text-center my-4'>
            <h1>Crear nueva notificación</h1>
        </Col>
    </Row>
    <Row>
    	<Col span={24}>
			<Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} defaultValue={{ type: '' }} autoComplete="off" layout='vertical'>
				<CRow>
          <Col md={12} xs={24}>
            <Form.Item label="Nombre" name="name"
                rules={[
                    {
                        required: true,
                        message: 'El nombre es requerido!',
                    }
                ]}>
                <Input placeholder='Ingrese el nombre' />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="Descripción" name="description">
                <Input placeholder='Ingrese una descripción' />
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
					<Col md={12} xs={24}>
						<Form.Item label="Fecha inicio" name="date_ini"
                  rules={[
                      {
                          required: true,
                          message: 'La fecha inicial es requerida!',
                      }
                  ]}>
                  <Input type="date" />
              </Form.Item>
          </Col>
          <Col md={12} xs={24}>
						<Form.Item label="Fecha final" name="date_end">
              <Input type="date" />
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
          <Col md={12} xs={24}>
            <Form.Item label="Correo electrónico" name="email"
              rules={[
                  {
                    required: true,
                    message: 'El correo electrónico es requerido!',
                  }
              ]}>
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col md={12} xs={24} >
              <Form.Item label="Frecuencia"  name="frequence"
                rules={[
                  {
                    required: true,
                    message: 'La frecuencia es requerida!',
                  }
              ]}>
                  <Select placeholder="Seleccione una opción" options={[
                      { value: 'una_vez', label: 'Solo una vez' },
                      { value: 'diario', label: 'Diario' },
                      { value: 'semanal', label: 'Semanal' },
                      { value: 'quincenal', label: 'Cada 15 del mes' }
                    ]}>
                  </Select>
              </Form.Item>
          </Col>
        </CRow>
        <CRow>
          <Col md={24} xs={24}>
            <Form.Item label="Asunto"  name="subject"
                rules={[
                    {
                        required: true,
                        message: 'El asunto es requerido!',
                    }
                ]}>
                <Input type="text" placeholder='Asunto' />
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
          <Col span={24}  md={24} xs={24}>
            <Form.Item label="Mensaje"  name="message"
                rules={[
                    {
                        required: true,
                        message: 'El mensaje es requerido!',
                    }
                ]}>
                <TextArea placeholder='Mensaje a enviar'></TextArea>
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
              <Col span={24}  md={24} xs={24} className='text-center'>
                  <Form.Item>
                      <Button type='primary'  htmlType="submit" disabled={disabledButton}>Guardar Notificación</Button>
                  </Form.Item>
              </Col>
          </CRow>
			  </Form>
    	</Col>
    </Row>
    </CContainer>
  )
}

export default New;
