const ENDPOINTS = {
    MAKES: "makes/",
    YEARS: "years/",
    MODELS: "models/",
    VERSIONS: "versions/",
    PRICES: "prices/",
    QUOTES: "quotes/",
    BITACORA: "bitacora/",
    USERS: "users/",
    DRIVER: "driver/",
    ONEDRIVE: "onedrive/",
    VEHICULOS: "vehicles/",
    CARS: "cars/",
    S3: 'uploads3/',
    NOTIFICACIONES: 'notifications/',
    CLIENTES: 'clients/',
    LOGMAIL: 'logmail/',
    UPLOADS: 'upload',
    RETOMA: 'retoma',
    REPORTE: 'reporte',
    RECEPCION: 'recepcion',
    ACTAS: 'actas',
    TRAMITES: 'tramites',
    BONOS: 'bonos',
    PRUEBA_RUTAS: 'pruebas-ruta',
    TRIPIPE: 'tripipe',
}

export default ENDPOINTS;
