import API from "./Config";
import ENDPOINTS from "./Enpoints";

const TripipeService = {
    get: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    updateDealsPosicion: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.TRIPIPE+"/change-pos", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    addNewTask: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/add-task", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    addNewNote: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/add-note", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getTAsk: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
}

export default TripipeService;
