import React, { useState, useEffect } from 'react'
import { CRow, CCol, CFormLabel, CFormInput, CButton, CCarousel, CCarouselItem, CFormCheck } from '@coreui/react';
import { useNavigate, useParams } from "react-router-dom";
import VehiculosService from "src/services/VehiculosService";
import ImageUploading from 'react-images-uploading';
import { Puff } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import $ from "jquery";
import { Switch } from 'antd';

const Edit = () =>{
    const navigate = useNavigate();
    var { id } = useParams();
    const [showLot, setShowLot] = useState(false);
    const moneyFormat = (value) => new Intl.NumberFormat().format(value)+" COP";
    const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
    const [item, setItem] = useState({
        make: '',
        year: '',
        model: '',
        version: '',
        vehicle_type: '',
        vehicle_body_type: '',
        transmission: '',
        fuel_type: '',
        color: '',
        kilometers: '',
        price: 0,
        status: '',
        title: '',
        plate: '',
        img_main: '',
        pictures: '',
        published: 0,
        separate: 0,
        is_triplo: 0
    });
    const [mainImage, setMainImage] = useState([]);
    const [otherImages, setOtherImages] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [errorMainImage, setErrorMainImage] = useState("");
    const [errorPlaca, setErrorPlaca] = useState("");
    const [errorOther, setErrorOther] = useState("");
    const [showLoad, setShowLoad] = useState(false);
    const [showLoad2, setShowLoad2] = useState(false);
    const [isTriploV, setIsTriploV] = useState(false);
    const [statusV, setStatus] = useState(false);
    const [plateValue, setPlateValue] = useState('');
    const [cargarAdicionales, setCargarAdicionales] = useState(false);

    useEffect(() => {
        getItem();
    }, []);

    const onChangeMainImage = (imageList, addUpdateIndex) => {
        setMainImage(imageList);
    };

    const onChangeOtherImages = (imageList, addUpdateIndex) => {
        setOtherImages(imageList);
    };

    const getItem = () =>{
        VehiculosService.get(id).then(resp =>{
            setItem(resp.response);
            setIsTriploV(resp.response.is_triplo);
            console.log(resp.response.statusml)
            setStatus(resp.response.statusml == 'active' ? true : false)
            if(resp.response.published === 1){
                let photos = resp.response.pictures.split(',');
                setPhotos(photos);
                setPlateValue(resp.response.plate)
            }
            if(resp.response.plate){
                setPlateValue(resp.response.plate)
            }
        }).catch(error => console.log(error));
    }

    const save = async () =>{  
        setErrorMainImage("");
        setErrorOther("");
        setErrorPlaca("");

        if(cargarAdicionales){
            if(otherImages.length === 0){
                setErrorOther("Debe agregar al menos una imágen adicional.")
            }else{
                $('body,html').stop(true,true).animate({				
                    scrollTop: $('body').offset().top
                },100);
                setShowLoad(true);
                setShowLot(true)
                let _data = {
                    main: '',
                    pictures: '',
                    placa: plateValue,
                    kilometraje: item.kilometers,
                    
                }  
                _data.main = item.img_main;
                _data.pictures = item.pictures+",";
                otherImages.forEach( async (element, index) => {
                    const data = new FormData() 
                    data.append('file', element.file); 
                    await VehiculosService.uploadPhoto(data, "auto_"+id).then(response =>{
                        _data.pictures += response.uri+",";
                    })
                    if((index + 1) == otherImages.length){
                        _data.pictures = _data.pictures.slice(0, -1);
                        await VehiculosService.saveImagens(_data, id).then(response2 =>{ 
                            toast.success('Vehículo guardado exitosamente', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });                           
                            setTimeout(() => {
                                setShowLoad(false);    
                                setShowLot(false)                            
                               navigate('/vehiculos');
                            }, 5000);
                        })
                    }
                })
            }
        }else{
            if(plateValue.length === 0){
                setErrorPlaca("Debe agregar la placa del vehículo");
            }else if(mainImage.length != 1){
                setErrorMainImage("Debe agregar una imagen principal.")
            }else if(otherImages.length < 5){
                setErrorOther("Debe agregar al menos cinco (5) imágenes adicionales.")
            }else{
                $('body,html').stop(true,true).animate({				
                    scrollTop: $('body').offset().top
                },100);
                setShowLoad(true);
                setShowLot(true)
                //VehiculosService.createFolder("auto_"+id).then(async resp =>{
                    let _data = {
                        main: '',
                        pictures: ''
                    }                
                    const data = new FormData() 
                    data.append('file', mainImage[0].file);                
                    data.append('isPortada', true);                
                    await VehiculosService.uploadPhoto(data, "auto_"+id).then(response =>{
                        _data.main = response.uri;
                    })

                    otherImages.forEach( async (element, index) => {
                        const data = new FormData() 
                        data.append('file', element.file); 
                        await VehiculosService.uploadPhoto(data, "auto_"+id).then(response =>{
                            _data.pictures += response.uri+",";
                        })
                        if((index + 1) == otherImages.length){
                        _data.pictures = _data.pictures.slice(0, -1);
                            await VehiculosService.saveImagens(_data, id).then(response2 =>{                            
                                setTimeout(() => {
                                    setShowLoad(false);
                                    setShowLot(false)
                                    savePlate()
                                }, 5000);
                            })
                        }
                    })
                    
                //}).catch(error => console.log(error));
            }  
        }
    }

    const separar = (condicion) =>{
        //Guardar como separado el carro...
        console.log(separar);
        let data = {
            id: id,
            value: condicion
        }
        VehiculosService.changeSeparate(data).then(resp =>{
            console.log(resp)
        }).catch(error => console.log(error))
    }

    const vender = (condicion) =>{
        //Guardar como vendido el carro...
        console.log("Vender");
        let data = {
            id: id,
            value: condicion
        }
        VehiculosService.changeSale(data).then(resp =>{
            console.log(resp)
        }).catch(error => console.log(error))
    }

    const setPlate = (event) =>{
        setPlateValue(event.target.value.toUpperCase());
        setShowLoad2(true);
        setShowLot(true)
        let data = {
            id: id,
            plate: event.target.value.toUpperCase()
        }
        VehiculosService.savePlate(data).then(resp =>{
            setTimeout(() => {
                setShowLoad2(false);
                setShowLot(false)
                toast.success('Placa actualizada con éxito', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }, 2000);
        }).catch(error => console.log(error))   
    }

    const savePlate = () =>{
        $('body,html').stop(true,true).animate({				
            scrollTop: $('body').offset().top
        },100);
        setShowLoad2(true);
        setShowLot(true)
        let data = {
            id: id,
            plate: plateValue.toUpperCase()
        }
        VehiculosService.savePlate(data).then(resp =>{
            setTimeout(() => {
                setShowLoad2(false);
                setShowLot(false)
                getItem()
                toast.success('Vehículo guardado exitosamente', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                navigate('/vehiculos');
            }, 5000);
        }).catch(error => console.log(error))        
    }

    const isTriplo = (checked) =>{
        let data = {
            id: id,
            value: checked ? 1 : 0
        }
        VehiculosService.changeIsTriplo(data).then(resp =>{
            setIsTriploV(checked)
        }).catch(error => console.log(error))
        
    }

    const changeStatus = (checked) =>{
        let data = {
            id: item.id_mercado_libre,
            value: checked ? 'active' : 'inactive'
        }
        VehiculosService.changeStatus(data).then(resp =>{
            setStatus(checked)
        }).catch(error => console.log(error))
        
    }


    return(<>
        <CRow>
            <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" /> 
            </div>   
            <CCol xs={12} className="text-center">
                <h2>Editar: { item.title ? item.title : ''}</h2>
                <Puff
                    height="80"
                    width="80"
                    radius={1}
                    color="#c14717"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={ showLoad2 }
                />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    />
                    {/* Same as */}
                <ToastContainer />
            </CCol>
        </CRow>
        <CRow className='mt-4'>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Marca</CFormLabel>
                    <CFormInput value={item ? item.make : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Año</CFormLabel>
                    <CFormInput value={item ? item.year : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Modelo</CFormLabel>
                    <CFormInput value={item ? item.model : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Versión</CFormLabel>
                    <CFormInput value={item ? item.version : ''} disabled></CFormInput>
                </div>
            </CCol>
        </CRow>
        <CRow className='mt-4'>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Tipo de vehículo</CFormLabel>
                    <CFormInput value={item ? item.vehicle_type : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Tipo de carroceria</CFormLabel>
                    <CFormInput value={item ? item.vehicle_body_type : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Transmisión</CFormLabel>
                    <CFormInput value={item ? item.transmission : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Tipo de combustible</CFormLabel>
                    <CFormInput value={item ? item.fuel_type : ''} disabled></CFormInput>
                </div>
            </CCol>
        </CRow>
        <CRow className='mt-4'>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Color</CFormLabel>
                    <CFormInput value={item ? item.color : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Kilometraje</CFormLabel>
                    <CFormInput value={item ? kmFormat(item.kilometers) : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Precio</CFormLabel>
                    <CFormInput value={item ? moneyFormat(item.price) : ''} disabled></CFormInput>
                </div>
            </CCol>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Estado</CFormLabel>
                    <CFormInput value={item ? (item.status == 'active' ? 'Activo' : 'Inactivo') : ''} disabled></CFormInput>
                </div>
            </CCol>
        </CRow>
        <CRow>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>¿Vehículo separado?</CFormLabel>
                    <div>
                        <CFormCheck button={{ color: 'success', variant: 'outline' }} type="radio" name="options-outlined" id="success-outlined" autoComplete="off" label="SI" 
                        defaultChecked={item.separate === 1 ? true : false} onChange={ () => separar(1) } />
                        <CFormCheck button={{ color: 'danger', variant: 'outline' }} type="radio" name="options-outlined" id="danger-outlined" autoComplete="off" className='mx-2'
                        defaultChecked={item.separate === 0 ? true : false} onChange={ () => separar(0) }  label="NO"/>
                    </div>                    
                </div>
            </CCol> 
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>Placa</CFormLabel>
                    <CFormInput placeholder='Ejemplo: ABC321' className='text-uppercase' defaultValue={ plateValue } onBlur={ setPlate }></CFormInput>
                    {/* <CButton className='ms-2' onClick={ savePlate }>Guardar</CButton> */}
                    <p className='small'>{ errorPlaca }</p>
                </div>
            </CCol>   
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>¿Vehículo vendido?</CFormLabel>
                    <div>
                        <CFormCheck button={{ color: 'success', variant: 'outline' }} type="radio" name="options-outlined-sale" id="success-outlined-sale" autoComplete="off" label="SI" 
                        defaultChecked={item.is_sale === 1 ? true : false} onChange={ () => vender(1) } />
                        <CFormCheck button={{ color: 'danger', variant: 'outline' }} type="radio" name="options-outlined-sale" id="danger-outlined-sale" autoComplete="off" className='mx-2'
                        defaultChecked={item.is_sale === 0 ? true : false} onChange={ () => vender(0) }  label="NO"/>
                    </div>                    
                </div>
            </CCol>  
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>¿Vehículo de Triplo?</CFormLabel>
                    <div>
                        <Switch checked={isTriploV} onClick={ isTriplo } />
                    </div>                    
                </div>
            </CCol>         
        </CRow>
        <CRow>
            <CCol xs={3}>
                <div className='form-group'>
                    <CFormLabel>¿Status? inactivo/ activo</CFormLabel>
                    <div>
                        <Switch checked={statusV} onClick={ changeStatus } />
                    </div>                    
                </div>
            </CCol> 
        </CRow>
        {
            item.published === 1 ?
            (
                <CRow className='mt-4'>
                    <CCol xs={6}>
                        <div className='form-group'>
                            <CFormLabel>Foto principal</CFormLabel>
                            <img className='d-block w-100' src={item.img_main} />
                        </div>
                    </CCol>
                    <CCol xs={6}>
                        <div className='form-group'>
                            <CFormLabel>Fotos adicionales</CFormLabel>
                            <CCarousel controls>
                            {
                                photos.map(
                                    (item, index) => (
                                        <CCarouselItem>
                                            <img key={index} src={item} className='d-block w-100' />
                                        </CCarouselItem>
                                    )
                                )
                            }
                            </CCarousel>
                        </div>
                    </CCol>
                </CRow>
            ): ''
        }
        {
            item.published === 1 ?
            '' :
            (
                <>
                    <CRow className='mt-4'>
                        <CCol xs={12}>
                            <h3>Carga de fotos</h3>
                        </CCol>
                        <CCol xs={12}>
                            <div className='form-group'>
                                <CFormLabel>Foto principal</CFormLabel>
                                <p className='small'>{ errorMainImage }</p>
                                <ImageUploading
                                    value={ mainImage }
                                    onChange={ onChangeMainImage }
                                    dataURLKey="data_url">
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                        }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <button
                                                style={isDragging ? { color: 'red' } : undefined}
                                                onClick={onImageUpload}
                                                className="btn_upload"
                                                {...dragProps} >
                                                Haga clic o Suelte aquí
                                            </button>
                                            &nbsp;
                                            <button className='btn_dd' onClick={onImageRemoveAll} disabled={ mainImage.length === 0}>Remover todas las imágenes</button>
                                            <div className='imgList'>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image['data_url']} alt="" width="100" />
                                                        <div className="image-item__btn-wrapper">
                                                            <button className='btn_dd' onClick={() => onImageUpdate(index)}>Actualizar</button>
                                                            <button className='btn_dd' onClick={() => onImageRemove(index)}>Remover</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </ImageUploading> 
                            </div>
                        </CCol>
                        <CCol xs={12}>
                            <div className='form-group'>
                                {/* {
                                    item.pictures != '' ? 
                                    (
                                        <div className="form-check">
                                            <input className='form-check-input' type='checkbox' onChange={ changeCargaAdicional } />
                                            <label className="form-check-label" >
                                                Marque si desea cargar únicamente adicionales 
                                            </label>
                                        </div>
                                    ): ''
                                } */}
                                
                                <CFormLabel>Resto de imágenes</CFormLabel>
                                <p className='small'>{ errorOther }</p>
                                <ImageUploading
                                    multiple
                                    value={ otherImages }
                                    onChange={ onChangeOtherImages }
                                    dataURLKey="data_url">
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                        }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <button
                                                style={isDragging ? { color: 'red' } : undefined}
                                                onClick={onImageUpload}
                                                className="btn_upload"
                                                {...dragProps} >
                                                Haga clic o Suelte aquí
                                            </button>
                                            &nbsp;
                                            <button className='btn_dd' onClick={onImageRemoveAll} disabled={ otherImages.length === 0}>Remover todas las imágenes</button>
                                            <div className='imgList'>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image['data_url']} alt="" width="100" />
                                                        <div className="image-item__btn-wrapper">
                                                            <button className='btn_dd' onClick={() => onImageUpdate(index)}>Actualizar</button>
                                                            <button className='btn_dd' onClick={() => onImageRemove(index)}>Remover</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </ImageUploading> 
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs={12}>
                            <Puff
                                height="80"
                                width="80"
                                radius={1}
                                color="#c14717"
                                ariaLabel="puff-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={ showLoad }
                            />
                            <div className='form-group text-center'>
                                <CButton onClick={save}>Actualizar</CButton>
                            </div>
                        </CCol>
                    </CRow>
                </>
            )
        }        
    </>)
}

export default Edit;