import React ,{ useEffect, useState } from 'react'
import { Col, Input, Row, Form, Button } from "antd";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CButton, CFormCheck } from '@coreui/react';

const CreateEmail = () => {
    const onFinishFailed = (values) => {
        console.log('Failed:', values);
    };
    const onFinish = (values) => {
        console.log('Finish:', values);
    }
    return(<>
        <Row>
            <Col span={24} className="text-center my-4">
                <h1>Nuevo Template</h1>
            </Col>
        </Row>
        <Form name="basic"
        onFinish={onFinish} onFinishFailed={onFinishFailed} defaultValue={{ transporte: '' }}
        autoComplete="off" layout='vertical'>
            <Row className='mb-4'>
                <Col span={24}>
                    <Form.Item label="Nombre" name="name"
                        rules={[
                            {
                                required: true,
                                message: 'El nombre es requerido!',
                            },
                        ]}>
                        <Input placeholder='Ingrese el nombre' />
                    </Form.Item>
                </Col>            
            </Row>
            <Row>
                <Col span={24} className='mb-4'>
                    <Form.Item label="Contenido" name="content"
                        rules={[
                            {
                                required: true,
                                message: 'El contenido es requerido!',
                            },
                        ]}>
                        <CKEditor
                            editor={ ClassicEditor }
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col span={24}>
                    <Form.Item label="¿Activo?" name="active">
                        <CFormCheck id="flexCheckChecked" label="¿Usar esta plantilla por defecto?" defaultChecked />
                    </Form.Item>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col span={24} className='text-center'>
                    <Button type='primary' htmlType="submit">Guardar</Button>
                </Col>
            </Row>
        </Form>
    </>)
}

export default CreateEmail;