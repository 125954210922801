import API from "./Config";

const DashboardService = {
    getCampanas: () => new Promise((resolve, reject) => {
        API.get("mailchimp/get-campaigns")
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    getCampana: (id) => new Promise((resolve, reject) => {
        API.get("mailchimp/get-campaign/"+id)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    getUnsubscribed: (id) => new Promise((resolve, reject) => {
        API.get("mailchimp/get-unsubscribed/"+id)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    })
}

export default DashboardService;
