import React ,{ useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import { CButton, CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell } from '@coreui/react';
import DriverService from 'src/services/Driver';
import { useNavigate  } from "react-router-dom";
import Moment from 'moment'
//const pageSize = 10;

const Emails = () => {
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [dataOr, setDataOr] = useState([]);
    const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentDataDisplayed, setCurrentDataDisplayed] = useState({ data:[]});
    const [previousAllowed, setPreviousAllowed] = useState(false);
    const [nextAllowed, setNextAllowed] = useState(true);
    const [buscador, setBuscador] = useState('');

    useEffect(() => {
        getData();
        setCurrentPage(1);
    }, [])

    useEffect(() => {
        data &&
          setCurrentDataDisplayed(() => {
            const page = data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
            return { data: page };
          });
        setPreviousAllowed(() => currentPage > 1);
        setNextAllowed(() => currentPage < numberOfPages);
    }, [currentPage, data]);

    const getData = () =>{
        
    }
    const handlePagination = (action) => {
        if (action === "prev") {
          if (!previousAllowed) return;
          setCurrentPage((prevState) => (prevState -= 1));
        }
        if (action === "next") {
          if (!nextAllowed) return;
          setCurrentPage((prevState) => (prevState += 1));
        }
    };

    const editar = (id) =>{
        
    }

    const consultar = (id) =>{
        
    }

    const goNew = () =>{
        navigate("/templates/nuevo/")
    }

    const eliminar = (id) =>{
        DriverService.delete(id).then(resp =>{
            window.location.reload();
        })
    }
    
    
    const changePageSize = (event) =>{
        if(event.target.value !== ''){
          setPageSize(parseInt(event.target.value));
          setCurrentDataDisplayed(() => {
            const page = data?.slice((currentPage - 1) * parseInt(event.target.value), currentPage * parseInt(event.target.value));
            return { data: page };
          });
          setPreviousAllowed(() => currentPage > 1);
          setNextAllowed(() => currentPage < numberOfPages);
          setNumberOfPages(Math.ceil(data.length / parseInt(event.target.value)));
        }
      }
    return(
        <Row>
            <Col span={24} className='text-center my-4'>
                <h1>Listado de templates</h1>               
            </Col>
            <Col span={24} className='text-end mb-4'>
                <a className='btn btn-primary' onClick={ goNew }>Nuevo template</a>
            </Col>
            <Col span={24}>
                <CTable className='table responsivegene'>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell>Fecha</CTableHeaderCell>
                            <CTableHeaderCell>Nombre</CTableHeaderCell>
                            <CTableHeaderCell>Activa</CTableHeaderCell>
                            <CTableHeaderCell>&nbsp;</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {
                            currentDataDisplayed.data.length > 0 ? 
                            (
                                currentDataDisplayed.data.map(
                                    (item, index) =>(
                                        <CTableRow key={index}>
                                            <CTableDataCell>
                                                { Moment(item.created_at).format("DD/MM/YYYY h:mm:ss a") }
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <strong>{ item.name }</strong>
                                            </CTableDataCell>                                            
                                            <CTableDataCell>
                                                { <span className={ item.active === true ? "badge rounded-pill text-bg-info" : "badge rounded-pill text-bg-danger"}>{ item.active === true ? 'Activo':'Inactivo' }</span> }
                                            </CTableDataCell>            
                                            <CTableDataCell className='text-end'>                                                 
                                                <CButton className='btn-info tt me-2' onClick={() => editar(item.id)}> Editar </CButton>
                                                <CButton className='btn-danger ' onClick={() => eliminar(item.id)}> Eliminar </CButton>
                                            </CTableDataCell>
                                        </CTableRow>
                                    )
                                )                                    
                            ):
                            (
                                <CTableRow>
                                    <CTableDataCell colSpan={8}>
                                        No existen templates
                                    </CTableDataCell>
                                </CTableRow>
                            )
                        }
                    </CTableBody>
                </CTable>
                {
                    currentDataDisplayed.data.length > 0 ? 
                    <div className='pag-custom d-flex justify-content-between'>
                    <div>
                        <div className='d-flex justify-content-center align-items-center'>
                        <select className='form-control spage' value={pageSize} onChange={ changePageSize }>
                            <option value="10">10</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <p>
                        Mostrando{" "}
                        <span>{pageSize * (currentPage - 1)}</span>{" "}
                        a{" "}
                        <span>
                            {currentDataDisplayed && currentDataDisplayed.data.length + (currentPage - 1) * pageSize}
                        </span>{" "}
                        de <span>{data?.length}</span>{" "} registros
                        </p>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => handlePagination("prev")} disabled={ (pageSize * (currentPage - 1)) === 0}>
                            Anterior
                        </button>
                        <button onClick={() => handlePagination("next")} disabled={ (currentDataDisplayed.data.length + (currentPage - 1) * pageSize) === data?.length }>
                            Siguientes 
                        </button>
                    </div>
                </div>: <></>
                }
                
            </Col>
        </Row>        
    )
}

export default Emails;