import React ,{ useState } from 'react'
import { Upload, Button, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import excel_person from '../../assets/carga_personalizada.xlsx';
import UploadService from 'src/services/UploadService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadData = () => {
	const [uploading, setUploading] = useState(false);
	const [uploadingFasecolda, setUploadingFasecolda] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [fileListFasecolda, setFileListFasecolda] = useState([]);

	const handleUpload = () => {
		const formData = new FormData();
		formData.append('file', fileList[0]);
		setUploading(true);
		UploadService.save(formData).then(response => {
			setFileList([]);
        	toast.success('Documento cargado con éxito', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
		}).catch(() => {
	        toast.error('Documento cargado con éxito', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
      	})
      	.finally(() => {
	        setUploading(false);
      	});
	}

	const handleUploadFasecolda = () => {

	}

	const props = {
	    onRemove: (file) => {
	      const index = fileList.indexOf(file);
	      const newFileList = fileList.slice();
	      newFileList.splice(index, 1);
	      setFileList(newFileList);
	    },
	    beforeUpload: (file) => {
	      setFileList([...fileList, file]);

	      return false;
	    },
	    fileList,
  	};

  	const propsFasecolda = {
	    onRemove: (file) => {
	      const index = fileListFasecolda.indexOf(file);
	      const newFileList = fileListFasecolda.slice();
	      newFileList.splice(index, 1);
	      setFileListFasecolda(newFileList);
	    },
	    beforeUpload: (file) => {
	      setFileListFasecolda([...fileListFasecolda, file]);

	      return false;
	    },
	    fileListFasecolda,
  	};

	return (
		<>
		<ToastContainer />
			<Row>
		        <Col className='text-center mb-4' span={24}>
	          		<h3>Carga de Marcas, modelos, años y versiones</h3>
		        </Col>
		    </Row>
		    
            <Row className="mb-4">
            	 <Col span={12}>
                	<p>Use esta opción si desea solo actualizar desde un archivo propio.</p>
                	<p><a href={excel_person} target="_blank">Aquí</a> puede descargar un referencia de ejemplo.</p>
        	 		<Upload {...props}>
				        <Button icon={<UploadOutlined />}>Seleccione el documento</Button>
			      	</Upload>
			      	<Button
				        type="primary"
				        onClick={handleUpload}
				        disabled={fileList.length === 0}
				        loading={uploading}
				        style={{ marginTop: 16 }}>
				        {uploading ? 'Cargando' : 'Iniciar carga'}
			      	</Button>
			   	</Col>
			    <Col span={12}>
				    <p>Use esta opción si desea solo actualizar desde un archivo fasecolda.</p>
                	<p>Aquí puede descargar un referencia de ejemplo.</p>
                	<Upload {...propsFasecolda}>
				        <Button icon={<UploadOutlined />}>Seleccione el documento</Button>
			      	</Upload>
			      	<Button
				        type="primary"
				        onClick={handleUploadFasecolda}
				        disabled={fileListFasecolda.length === 0}
				        loading={uploadingFasecolda}
				        style={{ marginTop: 16 }}>
				        {uploadingFasecolda ? 'Cargando' : 'Iniciar carga'}
			      	</Button>
        	 	</Col>
            </Row>            
		</>
	)
}
export default UploadData;