import React, { useState, useEffect } from 'react';
import { InboxOutlined, ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, message, Upload, Card, Form, Button } from 'antd';
import { useParams } from "react-router-dom";
import DriverService from 'src/services/Driver';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import { CCarousel, CCarouselItem, CImage  } from '@coreui/react';
import TextArea from 'antd/es/input/TextArea';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";

const { Dragger } = Upload;

const Fotos = () => {
  const { id } = useParams();
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [traslado, setTraslado] = useState({})
  const [direccionIni, setDireccionIni] = useState("")
  const [direccionEnd, setDireccionEnd] = useState("")
  const [fotosIniciales, setFotosIniciales] = useState([])
  const [fotosFinales, setFotosFinales] = useState([])
  const [direcciones, setDirecciones] = useState([])
  const [disabledButton, setDisabledButton] = useState(false);
  const [vehicleFoto, setVehicleFoto] = useState([]);

  const [index, setIndex] = useState(-1);
  var currentImage = vehicleFoto[index];
  const nextIndex = (index + 1) % vehicleFoto.length;
  const nextImage = vehicleFoto[nextIndex] || currentImage;
  const prevIndex = (index + vehicleFoto.length - 1) % vehicleFoto.length;
  const prevImage = vehicleFoto[prevIndex] || currentImage;

  const [vehicleFotoF, setVehicleFotoF] = useState([]);

  const [indexF, setIndexF] = useState(-1);
  var currentImageF = vehicleFotoF[indexF];
  const nextIndexF = (indexF + 1) % vehicleFotoF.length;
  const nextImageF = vehicleFotoF[nextIndexF] || currentImageF;
  const prevIndexF = (indexF + vehicleFotoF.length - 1) % vehicleFotoF.length;
  const prevImageF = vehicleFotoF[prevIndexF] || currentImageF;

  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const handleCloseF = () => setIndexF(-1);
  const handleMovePrevF = () => setIndexF(prevIndexF);
  const handleMoveNextF = () => setIndexF(nextIndexF);

  useEffect(() => {
    if(!authUser().id){
        navigate('/')
    }
    getItem();
  }, []);

  const goBack = () =>{
    navigate('/conductor')
  }

  const handleClick = (index) => {
    currentImage = vehicleFoto[index]
    setIndex(index);
  }

  const handleClickF = (index) => {
    currentImageF = vehicleFotoF[index]
    setIndexF(index);
  }

  const deleteImg = (currentImage) =>{
    DriverService.deletePhoto(currentImage.id).then(resp =>{
        toast.success('Foto eliminada con éxito', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
        setTimeout(() => {                          
            window.location.reload();
        }, 1200);
    })
  }

    const propsIniciales = {
        name: "file",
        multiple: true,
        action: "https://back.triplo.com.co/api/v1/uploads3/back-end/driver_"+id,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
           // $("#drag_ini .ant-upload-list")[0].outerHTML = "";
          }
          if (status === 'done') {
            message.success(`${info.file.name} foto agregada con éxito.`);
            let photo = {
                name: `IMG_INI_${id}_${info.file.name}`,
                url: info.file.response.uri,
                category: 'ini',
                vehicle_driver_id: id
            }
            DriverService.savePhoto(photo).then(x => getItem())
          } else if (status === 'error') {
            message.error(`${info.file.name} falló al cargar la foto.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const propsFinales = {
      name: "file",
      multiple: true,
      action: "https://back.triplo.com.co/api/v1/uploads3/back-end/driver_"+id,
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          //info.fileList = []
          //$("#drag_final .ant-upload-list")[0].outerHTML = "";
        }
        if (status === 'done') {
          message.success(`${info.file.name} foto agregada con éxito.`);
          let photo = {
              name: `IMG_END_${id}_${info.file.name}`,
              url: info.file.response.uri,
              category: 'end',
              vehicle_driver_id: id
          }
          DriverService.savePhoto(photo).then(x => getItem())
          
        } else if (status === 'error') {
          message.error(`${info.file.name} falló al cargar la foto.`);
        }
      },
      onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
      },
  };
    const getItem = async () =>{
      await DriverService.get(id).then(data =>{
        setTraslado(data.traslado)
        let _direcciones = data.direcciones
        setDireccionIni(_direcciones[0].direccion)
        setDireccionEnd(_direcciones[_direcciones.length - 1 ].direccion)
        setFotosIniciales(data.photosIni)
        let _images = [];
        data.photosIni.forEach(element => {
          _images.push({
              src: element.url,
              original: element.url,
              width: 500,
              height: 400,
              caption: element.url,
              id: element.id
          })
        });
        setVehicleFoto(_images);

        setFotosFinales(data.photosEnd)
        let _imagesF = [];
        data.photosEnd.forEach(element => {
          _imagesF.push({
              src: element.url,
              original: element.url,
              width: 500,
              height: 400,
              caption: element.url,
              id: element.id
          })
        });
        setVehicleFotoF(_imagesF);
        setDirecciones(_direcciones)
      })
    }
    const onFinish = async (values) => {      
      if(values.direcciones){
        values.direcciones.forEach((dir, index) => {
          let _direccion = {
              direccion : dir,
              id_traslado: id
          }
          DriverService.saveDireccion(_direccion).then(resp =>{
            if(index === values.direcciones.length - 1 ){
              values.direcciones = [];
              getItem()
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }
          })
      });
      }
    }
    return (
      <>
        <Row className='mb-4'>
          <Col xs={24} span={24} className='text-center'>
            <ArrowLeftOutlined className="iconBack" onClick={ goBack } title='Volver al listado'/>
            <h1>Carga de fotos</h1>
          </Col>
        </Row>
        <Row>
          <Col span={24} xs={24} md={24}>
            <Card title={"Placa: "+traslado.placa}>
              <p><strong>Kilometraje:</strong> { kmFormat(traslado.km) }</p>
              <div>
                <strong>Direcciones: </strong>
                <ul>
                {
                    direcciones.length > 1 ?
                    direcciones.map((direccion, index) => (
                      <li key={"dir"+index}>{ direccion.direccion }</li>
                    ))
                    : null
                }
                </ul>
              </div>              
              <p><strong>Detalle:</strong> { traslado.detalles }</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24} xs={24} md={24}>
            <Form name="basic" onFinish={onFinish} layout='vertical'>
              <Form.List name="direcciones">
              {(fields, { add, remove }, { errors }) => (
                  <>
                      {fields.map((field, index) => (
                          <Form.Item required={false} key={field.key} label="Dirección adicional">
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']} label="Dirección adicional"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Por favor agregue una dirección o borre este campo",
                              },
                              {
                                  min: 6,
                                  message: 'La dirección ingresada no es válida'
                              }
                            ]}
                            noStyle
                          >
                            <TextArea></TextArea>
                          </Form.Item>
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                          <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ float: 'right' }}
                              icon={<PlusOutlined />}
                          >
                              Agregar otra dirección
                          </Button>
                          <Form.ErrorList errors={errors} />
                      </Form.Item>
                      <Form.Item>
                        <button style={{ float: 'right' }} type="submit" className='btn btn-primary ms-2' disabled={disabledButton}>Guardar dirección</button>
                      </Form.Item>
                  </>
              )}
              </Form.List>
            </Form>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col span={24} md={24} xs={24}>
          <Card
            style={{
              marginTop: 16,
            }} type="inner" title={"Fotos iniciales ("+fotosIniciales.length+")"}>
            <Row>
              
              {
                vehicleFoto ? 
                <Col md={24} xs={24} className='text-center mb-4'>
                    <Gallery
                        images={vehicleFoto}
                        onClick={handleClick}
                        enableImageSelection={false}
                    />
                    {!!currentImage && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImage.original}
                            imageTitle={currentImage.caption}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.original}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.original}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                            toolbarButtons={[
                                <button className='btn btn-danger' type='button' onClick={ () => deleteImg(currentImage)}>Eliminar</button>
                            ]}
                        />
                    )}
                </Col> : null
                }
              <Col xs={24} span={24} id='drag_ini'>
                <Dragger {...propsIniciales}>
                  <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Arrastra las imágenes aquí o Haz clic!</p>
                  <p className="ant-upload-hint">
                    Seleccione las imágenes iniciales que desea agregar a éste traslado
                  </p>
                </Dragger>
              </Col>
            </Row> 
          </Card>
          </Col>
          <Col span={24} md={24} xs={24}>
          <Card
            style={{
              marginTop: 16,
            }} type="inner" title={"Fotos finales ("+fotosFinales.length+")"}>
              <Row>
              {
                vehicleFotoF ? 
                <Col md={24} xs={24} className='text-center mb-4'>
                    <Gallery
                        images={vehicleFotoF}
                        onClick={handleClickF}
                        enableImageSelection={false}
                    />
                    {!!currentImageF && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImageF.original}
                            imageTitle={currentImageF.caption}
                            mainSrcThumbnail={currentImageF.src}
                            nextSrc={nextImageF.original}
                            nextSrcThumbnail={nextImageF.src}
                            prevSrc={prevImageF.original}
                            prevSrcThumbnail={prevImageF.src}
                            onCloseRequest={handleCloseF}
                            onMovePrevRequest={handleMovePrevF}
                            onMoveNextRequest={handleMoveNextF}
                            toolbarButtons={[
                                <button className='btn btn-danger' type='button' onClick={ () => deleteImg(currentImageF)}>Eliminar</button>
                            ]}
                        />
                    )}
                </Col> : null
                }
                <Col xs={24} span={24}  id='drag_final'>
                  <Dragger {...propsFinales}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Arrastra las imágenes aquí o Haz clic!</p>
                    <p className="ant-upload-hint">
                      Seleccione las imágenes finales que desea agregar a éste traslado
                    </p>
                  </Dragger>
                </Col>
              </Row> 
            </Card>
          </Col>
        </Row>
      </>
        
    )
}

export default Fotos;