import React, {useRef, useEffect, useState} from 'react'
import { Form, Button, Col, Input, message } from 'antd';
import { CRow } from '@coreui/react';
import SignaturePad from 'react-signature-pad-wrapper';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import ImageUploading from 'react-images-uploading';
import VehiculosService from 'src/services/VehiculosService';
import PruebaRutasService from 'src/services/PruebaRutasService';
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import $ from "jquery";

const CreatePruebaRuta = () => {
    const [form] = Form.useForm();
    const signaturePadRef = useRef(null);
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [fileList, setFileList] = useState([]);
    const minNumber = 4;
    const [showLot, setShowLot] = useState(false);

    const onFinish = async (values) =>{
        try {
            if(values.fotos.length >= 4){
                $('body,html').stop(true,true).animate({
                    scrollTop: $('body').offset().top
                },100);
                setShowLot(true)
                const uploadPromises = values.fotos.map((element) => {
                    const data = new FormData();
                    data.append('file', element.file);
                    return VehiculosService.uploadPhoto(data, `ruta_${values.placa}`);
                });
        
                // Espera a que todas las imágenes se suban
                const responses = await Promise.all(uploadPromises);
        
                // Extrae las URLs de las imágenes
                const images = responses.map(response => response.uri);
        
                const _data = {
                    nombre: values.nombre,
                    apellido: values.apellido,
                    celular: values.celular,
                    email: values.email,
                    direccion: values.direccion,
                    color: values.color,
                    kilometraje: values.kilometraje,
                    modelo_marca: values.modelo_marca,
                    placa: values.placa,
                    cedula: values.cedula,
                    fotos: images,
                    user_id: authUser().id,
                    firma: signaturePadRef.current.toSVG()
                };
        
                // Guarda la información después de que todas las imágenes se hayan subido
                await PruebaRutasService.save(_data);
                message.success("Prueba de ruta creada exitosamente");
                setTimeout(() => {
                    setShowLot(false)
                    navigate("/prueba-ruta")
                }, 2000);
            }
            message.error("Debe subir al menos 4 fotos");
        } catch (error) {
            setShowLot(false)
            console.error("Error al subir imágenes o guardar datos:", error);
        }
    }

    const clearSignature = () => {
        if (signaturePadRef.current) {
          signaturePadRef.current.clear();
        }
    };

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
    }, []);

    return(
        <>
            <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" />
            </div>
            <CRow>
                <Col span={24} className='text-center my-4'>
                    <h1>Crear prueba de ruta</h1>
                </Col>
            </CRow>
            <Form name="basic" form={form} onFinish={onFinish} layout='vertical'>
                <CRow>
                    <Col md={24} xs={24}>
                        <h4>Datos del cliente</h4>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Nombre(s)" name="nombre"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Nombre(s)' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Apellido(s)" name="apellido"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Apellido(s)' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Celular" name="celular"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 3134569875' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Correo electrónico" name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: email@ejemplo.com' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Cédula de ciudadania" name="cedula"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ingrese la cédula' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Dirección" name="direccion"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ingrese la dirección' />
                        </Form.Item>
                    </Col>
                </CRow>
                <hr/>
                <CRow>
                    <Col md={12} xs={24}>
                        <Form.Item label="Modelo y marca" name="modelo_marca"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Clase SLK' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Kilometraje" name="kilometraje"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='number' placeholder='Ej: 45000' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Placa" name="placa"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: XXX123' />
                        </Form.Item>
                    </Col> 
                     
                    <Col md={12} xs={24}>
                        <Form.Item label="Color" name="color"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Negro' />
                        </Form.Item>
                    </Col> 
                </CRow>
                <hr />
                <CRow>
                <Col xs={24} span={24} id='drag_ini'>
                    <Form.Item label="Fotos de los documentos (4)"  name="fotos"
                        rules={[
                            {
                                required: true,
                                message: 'Las fotos son requeridas!',
                            }
                        ]}>
                        <ImageUploading
                            name="fotos"
                            multiple
                            value={fileList}
                            dataURLKey="data_url">
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                    <button type='button'
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        className="btn_upload"
                                        {...dragProps} >
                                        Haga clic o Suelte aquí
                                    </button>
                                    &nbsp;
                                    <button className='btn_dd' onClick={onImageRemoveAll} disabled={ imageList.length === 0}>Remover todas las imágenes</button>
                                    <div className='imgList'>
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <img src={image['data_url']} alt="" width="100" />
                                                <div className="image-item__btn-wrapper">
                                                    <button className='btn_dd' onClick={() => onImageUpdate(index)}>Actualizar</button>
                                                    <button className='btn_dd' onClick={() => onImageRemove(index)}>Remover</button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </ImageUploading> 
                        </Form.Item>
                    </Col>
                </CRow>
                <hr />
                <CRow className="ffirmas">
                    <Col span={24} xs={24} className='text-center mx-auto'>
                        <label>Firma del cliente</label>
                        <SignaturePad ref={signaturePadRef} options={{penColor: 'rgb(0, 0, 0)'}} />   
                    </Col>
                    <Col span={24} xs={24}>
                        <button type='button' onClick={clearSignature}>Borrar firma</button>
                    </Col>
                </CRow>
                <CRow className='my-4'>
                    <Col span={24} xs={24} md={24} className='text-center'>
                        <Button type='primary'  htmlType="submit">Generar</Button>
                    </Col>
                </CRow>
            </Form>
        </>    
    )
}

export default CreatePruebaRuta;
