import React ,{ useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { CRow } from '@coreui/react';
import { Col, Checkbox, Image } from 'antd';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import RecepcionService from 'src/services/RecepcionService';
import 'react-quill/dist/quill.bubble.css';

const VerRecepcion = () => {
    const [item, setItem] = useState({})
    const [fileListFront, setFileListFront] = useState(null);
    const [fileListBack, setFileListBack] = useState(null);
    const KMFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
    const MoneyFormat = (value) => new Intl.NumberFormat().format(value)+" COP";
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        setTimeout(() => {
            getItem()
        }, 2000);
    }, [])

    const getItem = () =>{
        RecepcionService.getOne(id).then(resp => {
            let data = resp.data;
            data = data[0]
            let _data = {
                vehiculo: data.vehiculo,
                placa: data.placa,
                kilometraje: data.kilometraje,
                valor_soat: data.valor_soat,
                valor_impuesto: data.valor_impuesto,
                estado_general: data.estado_general,
                nro_duenos: data.nro_duenos,
                segunda_llave: data.segunda_llave,
                kit_carretera: data.kit_carretera,
                llanta_repuesto: data.llanta_repuesto,
                perno_seguridad: data.perno_seguridad,
                herramientas: data.herramientas,
                estado_llanta_del: data.estado_llanta_del,
                estado_llanta_tra: data.estado_llanta_tra,
                observaciones: data.observaciones.replaceAll('\n', '<br/>') ,
                detalles: data.detalles.replaceAll('\n', '<br/>') ,
                pdf: data.pdf,
                gato: data.gato,
                prenda: data.prenda,
                prenda_obs: data.prenda_obs,
                asegurado: data.asegurado,
                asegurado_obs: data.asegurado_obs,
                mantenimiento: data.mantenimiento,
                mantenimiento_obs: data.mantenimiento_obs,
            }
            setFileListFront(data.tarjeta_propiedad_frente)
            setFileListBack(data.tarjeta_propiedad_atras)
            setItem(_data)
        })
    }


    const goBack = () =>{
        navigate('/recepcion')
    }
    return(
        <>
            <CRow>
                <Col md={24} xs={24} className='text-center mb-4'>
                    <h3>Ver Recepción</h3>       
                    { item.pdf != null ? <a target="_blank" href={item.pdf}>Descargar PDF!</a> : ''}
                </Col>
            </CRow>
            <CRow>
                <Col md={12} xs={24}>
                    <div className='form-group'>
                        <label>Foto frontal de tarjeta de propiedad</label><br/>
                        <Image width={200} src={fileListFront} placeholder="Ver"/>
                    </div>
                </Col> 
                <Col md={12} xs={24}>
                    <div className='form-group'>
                        <label>Foto posterior de tarjeta de propiedad</label><br/>
                        <Image width={200} src={fileListBack} placeholder="Ver"/>
                    </div>
                </Col> 
            </CRow>
            <hr/>
            <CRow>
                <Col md={8} xs={24}>
                    <div className='form-group'>
                        <label>Vehiculo</label>
                        <p>{ item.vehiculo }</p>
                    </div>
                    <div className='form-group'>
                        <label>Placa</label>
                        <p>{ item.placa }</p>
                    </div>
                    <div className='form-group'>
                        <label>Kilometraje</label>
                        <p>{ KMFormat(item.kilometraje) }</p>
                    </div>
                    <div className='form-group'>
                        <label>Valor SOAT</label>
                        <p>{ item.valor_soat ? MoneyFormat(item.valor_soat) : '-' }</p>
                    </div>
                    <div className='form-group'>
                        <label>Valor impuesto</label>
                        <p>{ item.valor_impuesto ? MoneyFormat(item.valor_impuesto) : '-'}</p>
                    </div>
                                      
                </Col> 
                <Col md={8} xs={24}>
                    <div className='form-group'>
                        <label>Estado del vehiculo</label>
                        <p>{ item.estado_general }</p>
                    </div>
                    <div className='form-group'>
                        <label>Nro de dueños</label>
                        <p>{ item.nro_duenos }</p>
                    </div>
                    <div className='form-group'>
                        <label>¿Tiene segunda llave?</label>
                        <p><Checkbox checked={item.segunda_llave} disabled></Checkbox></p>
                    </div>
                    <div className='form-group'>
                        <label>¿Tiene llanta de repuesto?</label>
                        <p><Checkbox checked={item.llanta_repuesto} disabled></Checkbox></p>
                    </div>
                    <div className='form-group'>
                        <label>¿Tiene herramientas?</label>
                        <p><Checkbox checked={item.herramientas} disabled></Checkbox></p>
                    </div>    
                                   
                </Col>
                <Col md={8} xs={24}>
                    <div className='form-group'>
                        <label>¿Tiene kit de carretera?</label>
                        <p><Checkbox checked={item.kit_carretera} disabled></Checkbox></p>
                    </div>
                    <div className='form-group'>
                        <label>¿Tiene perno de seguridad?</label>
                        <p><Checkbox checked={item.perno_seguridad} disabled></Checkbox></p>
                    </div>
                    <div className='form-group'>
                        <label>¿Tiene gato?</label>
                        <p><Checkbox checked={item.gato} disabled></Checkbox></p>
                    </div>
                    <div className='form-group'>
                        <label>Estado ruedas delanteras</label>
                        <p>{ item.estado_llanta_del }%</p>
                    </div>
                    <div className='form-group'>
                        <label>Estado ruedas traseras</label>
                        <p>{ item.estado_llanta_tra }%</p>
                    </div>                   
                </Col>               
            </CRow>
            <CRow>
                <Col md={8} xs={24}>
                    <div className='form-group'>
                        <label>¿Asegurado?</label>
                        <p><Checkbox checked={item.asegurado} disabled></Checkbox></p>
                        {
                            item.asegurado ? <p className='my-0'><strong>Nota: </strong>{item.asegurado_obs ? item.asegurado_obs : 'Ninguna'}</p> : ''
                        }
                    </div>  
                </Col>
                <Col md={8} xs={24}>
                    <div className='form-group'>
                        <label>¿Prenda?</label>
                        <p><Checkbox checked={item.prenda} disabled></Checkbox></p>
                        {
                            item.prenda ? <p className='my-0'><strong>Nota: </strong>{item.prenda_obs ? item.prenda_obs : 'Ninguna'}</p> : ''
                        }
                    </div>
                </Col>
                <Col md={8} xs={24}>
                    <div className='form-group'>
                        <label>¿Mantenimiento?</label>
                        <p><Checkbox checked={item.mantenimiento} disabled></Checkbox></p>
                        {
                            item.mantenimiento ? <p className='my-0'><strong>Nota: </strong>{item.mantenimiento_obs ? item.mantenimiento_obs : 'Ninguna'}</p> : ''
                        }
                    </div> 
                </Col>
            </CRow>
            <hr/>
            <CRow>
                <Col md={24} xs={24}>
                    <div className='form-group'>
                        <label>Detalles</label>           
                        <div className="desc1" dangerouslySetInnerHTML={{ __html: item.detalles }} />
                    </div>
                </Col>
                <hr/>
                <Col md={24} xs={24}>
                    <div className='form-group'>
                        <label>Observaciones</label>           
                        <div className="desc1" dangerouslySetInnerHTML={{ __html: item.observaciones }} />
                    </div>
                </Col>
            </CRow>
            <hr/>
            <CRow>
                <Col className='text-center'>
                    <button type='button' className='btn btn-primary' onClick={ goBack } >Volver</button>
                </Col>
            </CRow>
        </>
    )
}

export default VerRecepcion;