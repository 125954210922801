import React, { useState, useEffect, useCallback  } from 'react'
import { CCol, CRow, CButton } from "@coreui/react";
import DriverService from 'src/services/Driver';
import { useNavigate, useParams } from "react-router-dom";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import Moment from 'moment'
import JSZip from "jszip";
const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
};

const View = () =>{
    const { id } = useParams();
    const [item, setItem] = useState({
        response:{}
    });
    const [imagesIniciales, setImagesIniciales] = useState([])
    const [imagesFinales, setImagesFinales] = useState([])
    const [direcciones, setDirecciones] = useState([])
    const [index, setIndex] = useState(-1);
    const [index2, setIndex2] = useState(-1);
    const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
    const moneyFormat = (value) => "$ "+new Intl.NumberFormat().format(value);
    var currentImage = imagesIniciales[index];
    const nextIndex = (index + 1) % imagesIniciales.length;
    const nextImage = imagesIniciales[nextIndex] || currentImage;
    const prevIndex = (index + imagesIniciales.length - 1) % imagesIniciales.length;
    const prevImage = imagesIniciales[prevIndex] || currentImage;

    var currentImage2 = imagesFinales[index2];
    const nextIndex2 = (index2 + 1) % imagesFinales.length;
    const nextImage2 = imagesFinales[nextIndex2] || currentImage2;
    const prevIndex2 = (index2 + imagesFinales.length - 1) % imagesFinales.length;
    const prevImage2 = imagesFinales[prevIndex2] || currentImage2;
    const navigate = useNavigate();

    useEffect(() => {
        getItem();
    }, []);
    
    const download = async (term) =>{
        console.log(term)
        var images = term === 'ini' ? imagesIniciales : imagesFinales;
        var name_folder = term == 'ini' ? 'fotos_iniciales' : 'fotos_finales';
        const zip = new JSZip();

        for (let i = 0; i < images.length; i++) {
            const response = await fetch(images[i].src, { method: "GET", mode: 'cors'});
            const blob = await response.blob();
            console.log(images[i].src.split("/").pop())
            zip.file(images[i].src.split("/").pop(), blob);
        }

        const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
        })

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = name_folder+".zip";
        link.click();
    }

    const getItem = async () =>{
        await DriverService.get(id).then(data =>{
            let _item = item;
            _item.response = data.traslado;
            _item.response.direccion_recepcion = data.direcciones[0].direccion;
            _item.response.direccion_entrega = data.direcciones[data.direcciones.length - 1].direccion;
            let _imagesIni = [];
            let _imagesEnd = [];
            data.photosIni.forEach(element => {
                _imagesIni.push({
                    src: element.url,
                    original: element.url,
                    width: "auto",
                    height: "auto",
                    caption: element.name,
                })                
            });
            data.photosEnd.forEach(element => {
                _imagesEnd.push({
                    src: element.url,
                    original: element.url,
                    width: "auto",
                    height: "auto",
                    caption: element.name,
                })
            });
            setDirecciones(data.direcciones)
            setItem(_item);
            console.log(_imagesIni)
            setImagesIniciales(_imagesIni);
            setImagesFinales(_imagesEnd);
        })
    }

    const handleClick = (index, item) => {
        currentImage = imagesIniciales[index]
        setIndex(index);
    }
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const handleClick2 = (index, item) => {
        currentImage2 = imagesFinales[index]
        setIndex2(index);
    }
    const handleClose2 = () => setIndex2(-1);
    const handleMovePrev2 = () => setIndex2(prevIndex2);
    const handleMoveNext2 = () => setIndex2(nextIndex2);

    return(
        <>
            <CRow>
                <CCol className="text-center mb-4">
                    <h2>Consultar solicitud</h2>
                </CCol>
            </CRow>
            <CRow>
                <CCol xs={12}>
                    <h4>Información del vehículo</h4>
                    <ul>
                        <li><b>Fecha inicio: </b>{ Moment(item.response.created_at).format("DD/MM/YYYY h:mm:ss a") }</li>
                        <li><b>Placa</b> <b>{ item.response.placa ? item.response.placa.toUpperCase() : '' }</b></li>
                        <li><b>Kilometraje: </b>{ kmFormat(item.response.km) }</li>
                        <li><b>Detalles: </b> { item.response.detalles }</li>
                        <li><b>Estado: </b> <span className={ item.response.estado === 'Finalizado' ? "badge rounded-pill text-bg-info" : "badge rounded-pill text-bg-danger"}>{ item.response.estado }</span></li>
                        <li><b>Direcciones</b>
                            <ul>
                                {
                                    direcciones.length > 0 ?
                                    direcciones.map((direccion) => (
                                        <li>{ direccion.direccion }</li>
                                    )) : null
                                }
                            </ul>
                        </li>
                        <li><b>Fecha final:</b> { item.response.updated_at !== null ? Moment(item.response.updated_at).format("DD/MM/YYYY h:mm:ss a") : null }</li>
                        <li><b>Medio de transporte:</b> { item.response.transporte ? item.response.transporte : '' }</li>
                        <li><b>Costo del medio de transporte:</b> { item.response.costo ? moneyFormat(item.response.costo) : ''  }</li>
                        { imagesIniciales.length > 0 ? <li className='link-down' onClick={() => download('ini')}><b>Descargar fotos iniciales</b></li> : '' }
                        { imagesFinales.length > 0 ? <li className='link-down' onClick={() => download('end')}><b>Descargar fotos finales</b></li> : '' }
                    </ul>                    
                </CCol>
            </CRow>
            <CRow>
                <CCol xs={12} md={12} className='text-center'>
                    <h4>Fotos iniciales</h4>
                    <Gallery
                        images={imagesIniciales}
                        onClick={handleClick}
                        enableImageSelection={false}
                    />
                    {!!currentImage && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImage.original}
                            imageTitle={currentImage.caption}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.original}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.original}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                    )}
                </CCol>
            </CRow>
            <CRow className='my-4'>
                <CCol xs={12} md={12} className='text-center'>
                    <hr />
                </CCol>
            </CRow>
            <CRow>
                <CCol xs={12} md={12} className='text-center'>
                    <h4>Fotos finales</h4>
                    <Gallery
                        images={imagesFinales}
                        onClick={handleClick2}
                        enableImageSelection={false}
                    />
                    {!!currentImage2 && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImage2.original}
                            imageTitle={currentImage2.caption}
                            mainSrcThumbnail={currentImage2.src}
                            nextSrc={nextImage2.original}
                            nextSrcThumbnail={nextImage2.src}
                            prevSrc={prevImage2.original}
                            prevSrcThumbnail={prevImage2.src}
                            onCloseRequest={handleClose2}
                            onMovePrevRequest={handleMovePrev2}
                            onMoveNextRequest={handleMoveNext2}
                        />
                    )}
                </CCol>
            </CRow>
            
            <CRow>
                <CCol xs={12} className='text-center my-4'>
                    <CButton className='btn btn-primary' onClick={ () => navigate("/conductor") }>
                        Regresar
                    </CButton>
                </CCol>
            </CRow>
        </>
    )
}

export default View;