import API from "./Config";
import ENDPOINTS from "./Enpoints"
const VersionService = {
    getVersionsByModel: (modelId) => new Promise((resolve, reject) => {
      API.get(ENDPOINTS.VERSIONS+"/s/"+modelId)
      .then(
          res => res.data
        )
        .then(
          data => resolve(data)
        )
        .catch(
          err => reject(err)
        )
    }),
    saveVersions: (data, modelId) => new Promise((resolve, reject) => {
      API.post(ENDPOINTS.VERSIONS+"/y/"+modelId, data)
      .then(
          res => res.data
        )
        .then(
          data => resolve(data)
        )
        .catch(
          err => reject(err)
        )
    }),
    saveVersionsV2: (data, modelId) => new Promise((resolve, reject) => {
      API.post(ENDPOINTS.VERSIONS+"setversion/", data)
      .then(
          res => res.data
        )
        .then(
          data => resolve(data)
        )
        .catch(
          err => reject(err)
        )
    })
}

export default VersionService;