import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TripipeService from 'src/services/TripipeService';
import { Col, Modal, Row, message } from 'antd';
import $ from "jquery";
import { useAuthUser } from 'react-auth-kit';
import Moment from 'moment'
import { CRow } from '@coreui/react';
const moneyFormat = (value) => "COP "+ new Intl.NumberFormat().format(value);

const Index = () => {
    const [data, setData] = useState(null);
    const [columnSelected, setColumnSelected] = useState({title: ''});
    const [taskSelected, setTaskSelected] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [isLoading, setIsLoading] = useState(true); 
    const [isEdit, setIsEdit] = useState(false); 
    const [notes, setNotes] = useState([]); 
    const authUser = useAuthUser();

    const showModal = (task) =>{
        setTaskSelected(task)
        setTitleModal("Editar Deal");
        setIsEdit(true)
        $("[name=notas_add]").val('')
        let id = task.id.split("_");
        TripipeService.getTAsk(id[1]).then(resp =>{
            setNotes(resp.result.notes)
            let _task = resp.result.task[0]
            $("[name=marca_add]").val(task.title)
            $("[name=cname_add]").val(_task.name)
            $("[name=cemail_add]").val(_task.email)
            $("[name=cost_add]").val(_task.cost)
        })
        setIsModalOpen(true)
    }

    const handleOk = () => {
        //Guardar
        let _colum = columnSelected.id;
        if(!isEdit){
            let _data = {
                title: $("[name=title_add]").val(),
                user_id: authUser().id,
                costo: $("[name=cost_add]").val(),
                status: '',
                columnid: columnSelected.id,
                column_id: _colum.split("-")[1]
            }
            TripipeService.addNewTask(_data).then(resp =>{
                TripipeService.get().then(resp => {
                    setData(resp.result);  // Guarda los datos obtenidos
                    setIsLoading(false);  // Cambia el estado de carga a false
                    $("[name=title_add]").val('')
                    $("[name=cost_add]").val('')
                    $("[name=contact_add]").val('')
                    setIsModalOpen(false);
                }).catch(error => {
                    console.error('Error al obtener los datos:', error);
                    setIsLoading(false);  
                    setIsModalOpen(false);
                });
            })
        }else{
            //Update
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onDragEnd = (result) =>{
        const { destination, source, draggableId } = result;
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const startColumn = data.columns[source.droppableId];
        const finishColumn = data.columns[destination.droppableId];

        if (startColumn === finishColumn) {
            const newTaskIds = Array.from(startColumn.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...startColumn,
                taskIds: newTaskIds,
            };

            const newData = {
                ...data,
                columns: {
                  ...data.columns,
                  [newColumn.id]: newColumn,
                },
            };
        
            setData(newData);
        } else {
            const startTaskIds = Array.from(startColumn.taskIds);
            startTaskIds.splice(source.index, 1);
            const newStartColumn = {
              ...startColumn,
              taskIds: startTaskIds,
            };
      
            const finishTaskIds = Array.from(finishColumn.taskIds);
            finishTaskIds.splice(destination.index, 0, draggableId);
            const newFinishColumn = {
              ...finishColumn,
              taskIds: finishTaskIds,
            };
      
            const newData = {
              ...data,
              columns: {
                ...data.columns,
                [newStartColumn.id]: newStartColumn,
                [newFinishColumn.id]: newFinishColumn,
              },
            };
      
            setData(newData);
        }
        let column = destination.droppableId.split("-")[1];
        let task = draggableId.split("_")[1];
        let _data = {
            'column_id': column,
            'task_id': task
        }
        TripipeService.updateDealsPosicion(_data).then(resp => console.log(resp))
    }
    
    const addTask = (_column) =>{
        setIsEdit(false)
        setColumnSelected(_column)
        $("[name=title_add]").val('')
        $("[name=cost_add]").val('')
        $("[name=cname_add]").val('')
        setTitleModal("Nuevo Deal en "+_column.title);
        setIsModalOpen(true);
    }

    const addNote = () =>{
        if($("[name=notas_add]").val() !== '' && $("[name=notas_add]").val().length > 4){
            let _nota = {
                note: $("[name=notas_add]").val(),
                user_id: authUser().id,
                tri_deal_id: taskSelected.id.split("_")[1]
            }
            TripipeService.addNewNote(_nota).then(resp =>{
                message.success("Nota agregada con éxito");
                TripipeService.getTAsk(taskSelected.id.split("_")[1]).then(resp =>{
                    setNotes(resp.result.notes)
                    $("[name=notas_add]").val()
                })
            })
        }else{
            message.error("No se puede crear una nota vacia o de menos de 4 caracteres");
        }
    }

    useEffect(() => {
        const getData = () => {
            TripipeService.get().then(resp => {
              setData(resp.result);  // Guarda los datos obtenidos
              setIsLoading(false);  // Cambia el estado de carga a false
            }).catch(error => {
              console.error('Error al obtener los datos:', error);
              setIsLoading(false);  // Cambia el estado de carga a false incluso si hay un error
            });
        };
      
        getData();
    },[])

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    return(
        <div className='ctripipe'>
            <Modal className='ctri-modal' title={ titleModal } width={700} open={isModalOpen} okText="Guardar" cancelText="Cancelar" onOk={handleOk} onCancel={handleCancel}>
                <CRow className='mb-4'>
                    <Col span={16}>
                        <CRow>                            
                            <Col span={24}>
                                <div className="form-group">
                                    <input className="form-control" name='marca_add' placeholder='Marca'/>
                                </div>
                                <div className="form-group">
                                    <input className="form-control" name='modelo_add' placeholder='Modelo'/>
                                </div>
                                <div className="form-group">
                                    <input className="form-control" name='version_add' placeholder='Version'/>
                                </div>
                                <div className="form-group">
                                    <input className="form-control" name='placa_add' placeholder='Placa'/>
                                </div>
                                <div className="form-group">
                                    <input className="form-control" name='costo_add' placeholder='Costo'/>
                                </div>
                            </Col>
                        </CRow>                        
                    </Col>                    
                    <Col span={8}>
                        <CRow>
                            <Col span={24}>
                                <h5>Contácto</h5>
                            </Col>
                            <Col span={24}>
                                <div className="form-group">
                                    <input className="form-control" name='cname_add' placeholder='Nombres'/>
                                </div>
                                <div className="form-group">
                                    <input className="form-control" name='cemail_add' placeholder='Email'/>
                                </div>
                            </Col>
                            <Col span={24}>
                                <button style={{ float: 'right', marginTop: '2px'}} type='button' className='btn btn-sm btn-primary'>Guardar</button>
                            </Col>
                        </CRow>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={24} className='mb-2'>
                        <p className='lbl-modal mb-2'><strong>Notas</strong></p>
                        <textarea className='form-control' name='notas_add' placeholder='Notas'></textarea>
                        <button style={{ float: 'right', marginTop: '4px'}} type='button' className='btn btn-sm btn-primary' onClick={ addNote }>Agregar</button>
                    </Col>
                    <Col span={24}>                           
                        {notes.map((item, index) => (
                            <dl key={`note-${index}`} className='mb-0 border-bottom'>
                                <dt className='mb-1'><b>{item.names}: </b> <span style={{ float: 'right', fontSize: '12px'}}>{ Moment( item.created_at).format("DD/MM/YYYY h:mm:ss a")}</span></dt>
                                <dd>{ item.note}</dd>
                            </dl>         
                        ))}  
                    </Col>
                </CRow>
            </Modal>
            <DragDropContext onDragEnd={onDragEnd}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {   
                        data && data.columns && data.columnOrder.map((columnId) => {
                        const column = data.columns[columnId];
                        const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
                        const totalCosto = tasks.reduce((acumulador, objeto) => acumulador + objeto.cost, 0);

                        return (
                            column ? 
                            <Droppable droppableId={column.id} key={column.id}>
                                {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className='column'                                    
                                >
                                    <div className='d-flex justify-content-between'>
                                        <h3>{column.title}</h3>                                        
                                        <button className='btn btn_add' onClick={() => addTask(column)}>
                                            +
                                        </button>
                                    </div>
                                    <p className='desp'>{ moneyFormat(totalCosto) } - { tasks.length } deals</p>
                                    {tasks && tasks.map((task, index) => (
                                        task.id ? 
                                        <Draggable key={task.id} draggableId={task.id} index={index} >
                                            {(provided) => (
                                            <div onClick={() => showModal(task) }
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps} className='task'>
                                                <div>
                                                    <h6 className='mb-0'>{ task.content }</h6>  
                                                    <p className='contact'>{ task.contact ? task.contact : '-' }</p>                                          
                                                    <p className='mb-0'>{ moneyFormat(task.cost ? task.cost : 0) }</p>                                          
                                                </div>
                                            </div>
                                            )}
                                        </Draggable> : null
                                    ))}
                                    {provided.placeholder}
                                </div>
                                )}
                            </Droppable> : null
                            )
                        })
                    }
                </div>
            </DragDropContext>
        </div>
    )
}

export default Index;